import { Button, Modal, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

interface EndProcessModalProps {
  open: boolean;
  onClose: () => void;
  handleCreateOrder: () => void;
}

interface ProcessModalProps {
  open: boolean;
  onClose: () => void;
  question: string;
}

function EndProcessModal({
  open,
  onClose,
  handleCreateOrder,
}: EndProcessModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "2rem",
          borderRadius: "0.5rem",
          outline: "none",
        }}
      >
        <Typography variant="h5" gutterBottom>
          {t("endProcessMessage3")}
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Button
            variant="outlined"
            onClick={onClose}
            style={{ marginRight: "2rem" }}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleCreateOrder();
              onClose();
            }}
            color="primary"
          >
            {t("endProcess")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

function ProcessModal({ open, onClose, question }: ProcessModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Modal open={open} onClose={onClose}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          padding: "2rem",
          borderRadius: "0.5rem",
          outline: "none",
        }}
      >
        <Typography variant="h5" gutterBottom>
          {t(question)}
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "2rem",
          }}
        >
          <Button
            variant="outlined"
            onClick={onClose}
            style={{ marginRight: "2rem" }}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onClose();
            }}
            color="primary"
          >
            {t("endProcess")}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

// function ProcessModal({ open, onClose, question }: ProcessModalProps) {
//     const { t } = useTranslation();
//     const navigate = useNavigate();

//     // Function to prevent interaction with form elements when modal is open
//     const handleOverlayClick = (e) => {
//         e.stopPropagation(); // Prevent clicks on overlay from propagating to underlying elements
//     };

//     return (
//         <Modal open={open} onClose={onClose}>
//             <>
//                 <div
//                     style={{
//                         position: 'absolute',
//                         top: '50%',
//                         left: '50%',
//                         transform: 'translate(-50%, -50%)',
//                         backgroundColor: 'white',
//                         padding: '2rem',
//                         borderRadius: '0.5rem',
//                         outline: 'none',
//                     }}
//                 >
//                     <Typography variant="h5" gutterBottom>
//                         {t(question)}
//                     </Typography>
//                     <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
//                         <Button variant="outlined" onClick={onClose} style={{ marginRight: '2rem' }}>
//                             {t('cancel')}
//                         </Button>
//                         <Button variant="contained" onClick={()=> {onClose();}} color="primary">
//                             {t('endProcess')}
//                         </Button>
//                     </div>
//                 </div>
//                 {open && (
//                     <div
//                         style={{
//                             position: 'fixed',
//                             top: 0,
//                             left: 0,
//                             width: '100%',
//                             height: '100%',
//                             backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
//                             zIndex: 9999, // Ensure the overlay is on top of everything
//                         }}
//                         onClick={handleOverlayClick} // Prevent interaction with underlying elements
//                     />
//                 )}
//             </>
//         </Modal>
//     );
// };

export { ProcessModal, EndProcessModal };
export default EndProcessModal;
