import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';
import { FacilityTypes } from 'src/models/facility_types';

export interface CreateFacilityTypesRequest {
  name: string;
  description: string;
  org_id: number;
  address: string;
  country: number;
  province: number;
  town: number;
  nima: string;
}

export interface EditFacilityTypesRequest {
  facility_type_id: number;
  name: string;
  description: string;
  org_id: number;
  address: string;
  country: number;
  province: number;
  town: number;
  nima: string;
}

export const apiFacilityTypes = createApi({
  // reducerPath: 'api_facility_types',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getFacilityTypes: builder.mutation<FacilityTypes[], void>({
      query: () => ({
        url: 'api/facility-types/getAll',
        method: 'GET',
      }),
    }),
    deleteFacilityTypes: builder.mutation<any, number>({
      query: (id) => ({
        url: `api/facility-types/delete?facility_type_id=${id}`,
        method: 'DELETE',
      }),
    }),
    createFacilityTypes: builder.mutation<any, CreateFacilityTypesRequest>({
      query: (body) => ({
        url: 'api/facility-types/create',
        method: 'POST',
        body,
      }),
    }),
    updateFacilityTypes: builder.mutation<any, EditFacilityTypesRequest>({
      query: (CreateFacilityTypesRequest) => ({
        url: `api/facility-types/edit?facility_type_id=${CreateFacilityTypesRequest.facility_type_id}`,
        method: 'PUT',
        body: CreateFacilityTypesRequest,
      }),
    }),
    getFacilityTypeById: builder.mutation<FacilityTypes, number>({
      query: (id) => ({
        url: `api/facility-types/get?facility_type_id=${id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetFacilityTypesMutation,
  useDeleteFacilityTypesMutation,
  useCreateFacilityTypesMutation,
  useUpdateFacilityTypesMutation,
  useGetFacilityTypeByIdMutation,
} = apiFacilityTypes;
