import { createContext, useContext, useEffect, useState } from "react";
import { useGetLogisticCenterByOrgIdMutation } from "src/api/logisticCenters";
import { useAppSelector } from "src/hooks/store";

const DEFAULT_VALUE = {
  state: {
    user: {
      id: null,
      username: null,
      name: null,
      surname: null,
      organization_id: null,
      logistic_center_id: null,
    },
    flags: {
      can_change_organization: false,
      can_change_logistic_center: false,
    },
  },
  actions: {
    setOrganization: (id) => {},
    setLogisticCenter: (id) => {},
  },
};

const UserContext = createContext(DEFAULT_VALUE);

export const UserProvider = ({ children }) => {
  const [getLogisticCenters, getLogisticCentersResult] =
    useGetLogisticCenterByOrgIdMutation();
  const [value, setValue] = useState(DEFAULT_VALUE);
  const authUser = useAppSelector((state) => state.auth.user);

  const selectedOrganizationId =
    parseInt(localStorage.getItem("user_selected_organization_id")) || null;
  const selectedLogisticCenterId =
    parseInt(localStorage.getItem("user_selected_logistic_center_id")) || null;

  useEffect(() => {
    if (authUser === null) return;
    const organization_id =
      selectedOrganizationId ||
      authUser?.organization_id ||
      authUser?.default_organization_id;
    const logistic_center_id =
      selectedLogisticCenterId ||
      authUser?.logistic_center?.id ||
      authUser?.default_logistic_center;

    setValue({
      ...value,
      state: {
        ...value.state,
        user: {
          id: authUser?.id,
          username: authUser?.username,
          name: authUser?.name,
          surname: authUser?.surname,
          organization_id: organization_id,
          logistic_center_id: logistic_center_id,
        },
        flags: {
          can_change_organization: !authUser.logistic_center,
          can_change_logistic_center: !authUser.logistic_center,
        },
      },
    });

    if (!selectedOrganizationId) {
      localStorage.setItem("user_selected_organization_id", organization_id);
    }

    if (!selectedLogisticCenterId) {
      localStorage.setItem(
        "user_selected_logistic_center_id",
        logistic_center_id
      );
    }

    if (!logistic_center_id) {
      getLogisticCenters(organization_id);
    }
  }, [authUser]);

  useEffect(() => {
    const logisticCenters = getLogisticCentersResult.data;
    if (logisticCenters) {
      setValue({
        ...value,
        state: {
          ...value.state,
          user: {
            ...value.state.user,
            organization_id: logisticCenters[0].organization.id,
            logistic_center_id: logisticCenters[0].id,
          },
        },
      });
      localStorage.setItem(
        "user_selected_logistic_center_id",
        logisticCenters[0].id.toString()
      );
      window.location.reload();
    }
  }, [getLogisticCentersResult]);

  const setOrganization = (id: number) => {
    getLogisticCenters(id);
    localStorage.setItem("user_selected_organization_id", id.toString());
  };

  const setLogisticCenter = (id: number) => {
    setValue({
      ...value,
      state: {
        ...value.state,
        user: {
          ...value.state.user,
          logistic_center_id: id,
        },
      },
    });
    localStorage.setItem("user_selected_logistic_center_id", id.toString());
    window.location.reload();
  };

  return (
    <UserContext.Provider
      value={{
        ...value,
        actions: {
          setOrganization,
          setLogisticCenter,
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
