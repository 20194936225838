import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';
import { LogisticCenters } from 'src/models/logistic_centers';

export interface CreateLogisticCenterRequest {
  name: string;
  description: string;
  org_id: number;
  address: string;
  country: number;
  province: number;
  town: number;
  nima: string;
}

export interface EditLogisticCenterRequest {
  logistic_center_id: number;
  name: string;
  description: string;
  org_id: number;
  address: string;
  country: number;
  province: number;
  town: number;
  nima: string;
}

export const apiLogisticCenters = createApi({
  // reducerPath: 'api_logistic_centers',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getLogisticCenters: builder.mutation<LogisticCenters[], void>({
      query: () => ({
        url: 'api/logistic-centers/getAll',
        method: 'GET',
      }),
    }),
    createLogisticCenter: builder.mutation<any, CreateLogisticCenterRequest>({
      query: (body) => ({
        url: 'api/logistic-centers/create',
        method: 'POST',
        body,
      }),
    }),
    deleteLogisticCenter: builder.mutation<any, number>({
      query: (id) => ({
        url: `api/logistic-centers/delete?logistic_center_id=${id}`,
        method: 'DELETE',
      }),
    }),
    updateLogisticCenter: builder.mutation<any, EditLogisticCenterRequest>({
      query: (EditLogisticCenterRequest) => ({
        url: `api/logistic-centers/edit?logistic_center_id=${EditLogisticCenterRequest.logistic_center_id}`,
        method: 'PUT',
        body: EditLogisticCenterRequest,
      }),
    }),
    getLogisticCenterById: builder.mutation<LogisticCenters, number>({
      query: (id) => ({
        url: `api/logistic-centers/get?logistic_center_id=${id}`,
        method: 'GET',
      }),
    }),

    getLogisticCenterByOrgId: builder.mutation<LogisticCenters[], number>({
      query: (id) => ({
        url: `api/logistic-centers/get-by-organization?organization_id=${id}`,
        method: 'GET',
      }),
    }),

  }),
});

export const {
  useGetLogisticCentersMutation,
  useCreateLogisticCenterMutation,
  useDeleteLogisticCenterMutation,
  useUpdateLogisticCenterMutation,
  useGetLogisticCenterByIdMutation,
  useGetLogisticCenterByOrgIdMutation,  //JSM 20240416
} = apiLogisticCenters;
