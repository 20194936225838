// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-filters-container {
	display: flex;
}

.admin-filter {
	width: 200px !important;
	margin-right: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/layouts/SidebarLayout/Header/admin-organization-filters/admin-organization-filters.css"],"names":[],"mappings":"AAAA;CACC,aAAa;AACd;;AAEA;CACC,uBAAuB;CACvB,6BAA6B;AAC9B","sourcesContent":[".admin-filters-container {\n\tdisplay: flex;\n}\n\n.admin-filter {\n\twidth: 200px !important;\n\tmargin-right: 10px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
