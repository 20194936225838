export interface ProductStatus {
  status: string;
  description: string;
  id: number; //FIXME: should be "ProductStatusEnum"
}

export enum ProductStatusEnum {
  IN_PROCESS = 1,
  PENDING_CLASSIFICATION = 2,
  CLASSIFIED = 3,
  PROCESSED = 4,
  DELETED = 5,
  PACKED = 6,
}
