import { createSlice } from '@reduxjs/toolkit';
import { apiCompositions } from 'src/api/compositions';

type CompositionsState = {
  createdCompositions: any;
  messageCreated: string | null;
};

const DEFAULT_STATE: CompositionsState = {
  createdCompositions: [],
  messageCreated: null,
};

const initialState = () => {
  const persistedState = localStorage.getItem('reduxState');
  if (persistedState) {
    return JSON.parse(persistedState);
  }
  return DEFAULT_STATE;
};

export const compositionsSlice = createSlice({
  name: 'compositions',
  initialState: initialState,
  reducers: {
    createCompositionSuccess: (state, action) => {
      state.createdCompositions = action.payload;
    },
    resetCreatedCompositions: (state) => {
      state.createdCompositions = [];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiCompositions.endpoints.createComposition.matchFulfilled,
      (state, { payload: response }) => {
        state.createdCompositions.push(response);
      }
    );
  },
});

export default compositionsSlice.reducer;
export const { createCompositionSuccess, resetCreatedCompositions } =
  compositionsSlice.actions;
