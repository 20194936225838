import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "src/api/baseQuery";
import { TraceabilityDataDTO } from "src/components/TraceabilityChart/TraceabilityGraph.dto";
import { OrderStatusEnum } from "src/content/pages/ReceptionManagement/Pending/enums";
import { Colors } from "src/models/colors";
import { Composition } from "src/models/compositions";
import { IContainer, IContainerDataGridRow } from "src/models/container";
import { ProductStatus } from "src/models/product_status";
import { ProductType } from "src/models/product_types";
import { CompositionCreateRequest } from "./orders";

export interface Product {
  id: number;
  creation_date: Date;
  percentage: string;
  discarded: number;
  quantity: number;
  weight: string;
  estimated_waste: string;
  unit_weight: string;
  reclassification: number;
  new_color_id: number;
  new_composition_id: number;
  comments: string | null;
  closed_circulation: number | null;
  selvage: number | null;
  knitted: number | null;
  interlining: number | null;
  productTypeID: number;
  new_composition: any;
};

export interface ProductRequest {
  a3: number;
  ADLOTENS: string;
  comments: string;
  containers: IContainer[] | IContainerDataGridRow[];
  creation_date: string;
  discarded: number;
  estimated_waste: number;
  id?: number;
  LOTEFIRST3: string;
  MMYY: string;
  new_color_id: number;
  new_color?: Colors;  //JSM 20240327
  new_composition_id: number;
  next_process: number;
  OPLOTE: string;
  order_line_id: number;
  OUTPUT: string;
  percentage: number;
  previous_process: number;
  product_type: number;
  productTypeID?: number;
  quantity: number;
  reclassification: number;
  responsible: number;
  shipment: number;
  status: number;
  unit_weight: number;
  weight: number;
  new_composition?: Composition;
}

export interface AssignNextProcessRequest {
  products_id: number[];
  process_id: number;
}

export interface GetProductByIdRequest {
  product_id: number;
}

export interface DeleteProductRequest {
  product_id: number;
}
export interface CreateProductRequest {
  creation_date: Date;
  percentage: number;
  discarded: number;
  quantity: number;
  weight: number;
  estimated_waste: number;
  unit_weight: number;
  reclassification: number;
  new_color_id: number;
  new_composition_id: number;
  LOTEFIRST3: string;
  a3: number;
  comments: string;
  OPLOTE: string;
  OUTPUT: string;
  ADLOTENS: string;
  MMYY: string;
  product_type: number;
  next_process?: number;
  previous_process?: number;
  responsible: number;
  shipment: number;
  status: number;
  containers: {
    containerID: number;
    containerName: string;
    containerZoneID: number;
    containerZoneName: string;
    containerWeight: number;
  }[];
  order_line_id: number | null;
}

export interface ProductEditRequest {
  id: number;
  owner_id?: number;
	product_type_id: number;
	color_id?: number;
	composition_id?: number;
	closed_circulation?: number,
	composition?: CompositionCreateRequest;
	quantity?: number;
	weight?: number;
	comments?: string;
	status_id?: number;
	category_id?: number;
	waste_type_id?: number;
	creation_date?: string;
}

export const apiProduct = createApi({
  // reducerPath: 'api_product',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getAllProductTypes: builder.mutation<ProductType[], void>({
      query: () => ({
        url: "api/products/getAllProductsType",
        method: "GET",
      }),
    }),
    getAllProductStatus: builder.mutation<ProductStatus[], void>({
      query: () => ({
        url: "api/products/getAllProductsStatus",
        method: "GET",
      }),
    }),
    performReception: builder.mutation<
      IPerformReceptionResponse,
      IPerformReceptionRequest
    >({
      query: (ProductRequest) => ({
        url: "api/products/perform-reception",
        method: "POST",
        body: ProductRequest,
      }),
    }),
    getAllProducts: builder.mutation<any, void>({
      query: () => ({
        url: "api/products/getAllProducts",
        method: "GET",
      }),
    }),
    getProductById: builder.mutation<any, GetProductByIdRequest>({
      query: (GetProductByIdRequest) => ({
        url: `api/products/getProductsById?product_id=${GetProductByIdRequest.product_id}`,
        method: "GET",
      }),
    }),
    getProductsBySomeId: builder.mutation<any, IGetProductBySomeIdRequest>({
      query: (body) => ({
        url: "api/products/getProductsBySomeId",
        method: "POST",
        body
      }),
    }),

    deleteProduct: builder.mutation<any, DeleteProductRequest>({
      query: (DeleteProductRequest) => ({
        url: `api/products/deleteProducts?product_id=${DeleteProductRequest.product_id}`,
        method: "DELETE",
      }),
    }),
    assignNextProcess: builder.mutation<any, AssignNextProcessRequest>({
      query: (AssignNextProcessRequest) => ({
        url: "api/products/assignNextProcess",
        method: "PUT",
        body: AssignNextProcessRequest,
      }),
    }),
    getTraceProduct: builder.mutation<TraceabilityDataDTO, any>({
      query: (product_id) => ({
        url: `api/products/trace-product?product_id=${product_id}`,
        method: "GET",
      }),
    }),
    getsProductsByOrderNumber: builder.mutation<any, string>({
      query: (order_number) => ({
        url: `api/products/get-products-by-order-number?order_number=${order_number}`,
        method: "GET",
      }),
    }),
    createProduct: builder.mutation<any, Partial<CreateProductRequest>>({
      query: (body) => ({
        url: `api/products/createProducts`,
        method: "POST",
        body: body,
      })
    }),
    editProduct: builder.mutation<any, ProductEditRequest>({
      query: (body) => ({
        url: `api/products/editProduct`,
        method: "POST",
        body: body,
      })
    })
  }),
});

export const {
  useAssignNextProcessMutation,
  useDeleteProductMutation,
  useGetAllProductsMutation,
  useGetAllProductStatusMutation,
  useGetAllProductTypesMutation,
  useGetProductByIdMutation,
  useGetProductsBySomeIdMutation,
  useGetsProductsByOrderNumberMutation,
  useGetTraceProductMutation,
  usePerformReceptionMutation,
  useCreateProductMutation,
  useEditProductMutation,
} = apiProduct;

export interface IPerformReceptionResponse {
  orderStatus: OrderStatusEnum;
}
export interface IPerformReceptionRequest {
  composition: IPerformReceptionRequestComposition;
  product: IPerformReceptionRequestProduct;
  containers: IContainerDataGridRow[];
}
export interface IGetProductBySomeIdRequest {
  id: number[];
}
export interface IGetProductBySomeIdResponse {
  id: number[];
}

interface IPerformReceptionRequestComposition {
  id: number;
  description: string;
  organizationID: number;
  details: IPerformReceptionRequestDetail[];
}

interface IPerformReceptionRequestDetail {
  description: any;
  conventional: number;
  textileTypeID: number;
}

interface IPerformReceptionRequestProduct {
  a3: number;
  comments: string;
  creation_date: string;
  discarded: number;
  isSpecifyComposition: boolean;
  new_color_id: number;
  order_line_id: number;
  orderHeaderID: number;
  pallets: number;
  percentage: number;
  product_type: number;
  quantity: number;
  reclassification: number;
  responsible: number;
  shipment: number;
  status: number;
  unit_weight: number;
  weight: number;
  locationICont: number;
  centerICont: number;
}
