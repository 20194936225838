import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';
import { IContainer } from 'src/models/container';
import { IContainers } from 'src/models/containers';

export interface GetContainerByIdRequest {
  container_id: number;
}

export interface DeleteIContRequest {
  container_id: number;
}

export interface UpdateWeightRequest {
  container_id: number;
  weight: number;
}

export interface IGetContainerBySomeIdRequest {
  id: number[];
}

export const apiContainers = createApi({
  // reducerPath: 'api_containers',
  baseQuery: baseQueryWithReAuth,
  reducerPath: 'containers',
  endpoints: (builder) => ({
    getContainerByID: builder.mutation<any, GetContainerByIdRequest>({
      query: (GetContainerByIdRequest) => ({
        url: `api/containers/get-container-by-id?container_id=${GetContainerByIdRequest.container_id}`,
        method: 'GET',
      }),
    }),
    getContainersBySomeId: builder.mutation<any, IGetContainerBySomeIdRequest>({
      query: (body) => ({
        url: "api/containers/get-container-by-some-id",
        method: "POST",
        body
      })
    }),
    getAllContainers: builder.mutation<IContainers, void>({
      query: () => ({
        url: 'api/containers/get-void-containers',
        method: 'GET',
      }),
    }),
    getAllContainersInfo: builder.mutation<any, void>({
      query: () => ({
        url: 'api/containers/getAll',
        method: 'GET',
      }),
    }),
    getVoidContainers: builder.mutation<IContainer[], void>({
      query: () => ({
        url: 'api/containers/get-void-containers',
        method: 'GET',
      }),
    }),
    getCuttableContainers: builder.mutation<IContainer[], void>({
      query: () => ({
        url: 'api/containers/get-cuttable-containers',
        method: 'GET',
      }),
    }),
    updateContainerWeight: builder.mutation<any, UpdateWeightRequest>({
      query: (body) => ({
        url: 'api/containers/update-container-weight',
        method: 'PUT',
        body,
      }),
    }),
    updateContainer: builder.mutation<any, any>({
      query: (data) => ({
        url: `api/containers/edit?container_id=${data.container_id}`,
        method: 'PUT',
        body: data.data,
      }),
    }),
    createContainer: builder.mutation<any, any>({
      query: (body) => ({
        url: 'api/containers/create',
        method: 'POST',
        body,
      }),
    }),
    deleteContainer: builder.mutation<any, any>({
      query: (container_id) => ({
        url: `api/containers/delete?container_id=${container_id}`,
        method: 'DELETE',
      }),
    }),
    deleteIContainer: builder.mutation<any, DeleteIContRequest>({
      query: (DeleteIContRequest) => ({
        url: `api/containers/deleteICont?ICont_id=${DeleteIContRequest.container_id}`,
        method: 'DELETE',
      }),
    }),
    getContainerTypes: builder.mutation<any, void>({
      query: () => ({
        url: 'api/containers/getAllContainerTypes',
        method: 'GET'
      })
    })
  }),
});

export const {
  useGetContainerByIDMutation,
  useGetContainersBySomeIdMutation,
  useGetAllContainersMutation,
  useGetAllContainersInfoMutation,
  useGetVoidContainersMutation,
  useUpdateContainerWeightMutation,
  useUpdateContainerMutation,
  useCreateContainerMutation,
  useDeleteContainerMutation,
  useDeleteIContainerMutation,
  useGetCuttableContainersMutation,
  useGetContainerTypesMutation,
} = apiContainers;
