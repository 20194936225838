import { createSlice } from '@reduxjs/toolkit';
import { apiOrders } from 'src/api/orders';
import { apiShipments } from 'src/api/shipments';

type ShipmentState = {
  shipment: any;
  messageCreated: string | null;
};

const DEFAULT_STATE: ShipmentState = {
  shipment: null,
  messageCreated: null,
};

const initialState = () => {
  const persistedState = localStorage.getItem('reduxState');
  if (persistedState) {
    return JSON.parse(persistedState);
  }
  return DEFAULT_STATE;
};

export const shipmentsSlice = createSlice({
  name: 'shipments',
  initialState: initialState,
  reducers: {
    createShipmentSuccess: (state, action) => {
      state.shipment = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiShipments.endpoints.createShipment.matchFulfilled,
      (state, { payload: response }) => {
        state.shipment = response.shipment;
      }
    );
    builder.addMatcher(
      apiOrders.endpoints.createOrderLines.matchFulfilled,
      (state, { payload: response }) => {
        state.orderLine = response;
      }
    );
  },
});

export default shipmentsSlice.reducer;
export const { createShipmentSuccess } = shipmentsSlice.actions;
