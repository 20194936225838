import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReAuth } from "src/api/baseQuery";
import { Composition } from "src/models/compositions";
import { Organization } from "src/models/organization";

export interface CreateCompositionRequest {
  description: string;
  details: string;
  textil_waste: number;
  thread: string;
  clasification: string;
  organization_id: number;
}

export interface CreateCompositionResponse {
  message: string;
  composition: {
    description: string;
    details: string;
    thread: string;
    clasification: string;
    organization: Organization;
    id: number;
  };
}
export interface CalculateNewCompositionRequest {
  next_process_id: number;
}

export const apiCompositions = createApi({
  reducerPath: "api_compositions",
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getCompositions: builder.mutation<Composition[], void>({
      query: () => ({
        url: "api/compositions/getAll",
        method: "GET",
      }),
    }),
    calculateNewComposition: builder.mutation<
      any,
      CalculateNewCompositionRequest
    >({
      query: (CalculateNewCompositionRequest) => ({
        url: "api/composition-details/calculateNewComposition",
        method: "POST",
        body: CalculateNewCompositionRequest,
      }),
    }),
    createComposition: builder.mutation<
      CreateCompositionResponse,
      CreateCompositionRequest
    >({
      query: (body) => ({
        url: "api/compositions/create",
        method: "POST",
        body,
      }),
    }),
    createCompositionDetail: builder.mutation<any, any>({
      query: (body) => ({
        url: "api/composition-details/create",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetCompositionsMutation,
  useCalculateNewCompositionMutation,
  useCreateCompositionDetailMutation,
  useCreateCompositionMutation,
} = apiCompositions;
