import { Suspense, lazy } from "react";
import { RouteObject } from "react-router";
import { Navigate } from "react-router-dom";

import BaseLayout from "src/layouts/BaseLayout";
import SidebarLayout from "src/layouts/SidebarLayout";

import SuspenseLoader from "src/components/SuspenseLoader";
import { PrivateRoute } from "./components/PrivateRoute";
import ReceptionNoShipment from "./content/pages/ReceptionManagement/NoShipment";
import { ROLE } from "./models/roles";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import("src/content/login")));

// Dashboards

const Crypto = Loader(lazy(() => import("src/content/dashboards/Crypto")));

// Applications

const Messenger = Loader(
  lazy(() => import("src/content/applications/Messenger"))
);
const Transactions = Loader(
  lazy(() => import("src/content/applications/Transactions"))
);
// const UserProfile = Loader(
//   lazy(() => import('src/content/applications/Users/profile'))
// );
const UserSettings = Loader(
  lazy(() => import("src/content/applications/Users/settings"))
);

// Components

const Buttons = Loader(
  lazy(() => import("src/content/pages/Components/Buttons"))
);
const Modals = Loader(
  lazy(() => import("src/content/pages/Components/Modals"))
);
const Accordions = Loader(
  lazy(() => import("src/content/pages/Components/Accordions"))
);
const Tabs = Loader(lazy(() => import("src/content/pages/Components/Tabs")));
const Badges = Loader(
  lazy(() => import("src/content/pages/Components/Badges"))
);
const Tooltips = Loader(
  lazy(() => import("src/content/pages/Components/Tooltips"))
);
const Avatars = Loader(
  lazy(() => import("src/content/pages/Components/Avatars"))
);
const Cards = Loader(lazy(() => import("src/content/pages/Components/Cards")));
const Forms = Loader(lazy(() => import("src/content/pages/Components/Forms")));

// Status

const Status404 = Loader(
  lazy(() => import("src/content/pages/Status/Status404"))
);
const Status500 = Loader(
  lazy(() => import("src/content/pages/Status/Status500"))
);
const StatusComingSoon = Loader(
  lazy(() => import("src/content/pages/Status/ComingSoon"))
);
const StatusMaintenance = Loader(
  lazy(() => import("src/content/pages/Status/Maintenance"))
);

//AUTH
const LoginPage = Loader(lazy(() => import("src/content/pages/Login")));
const RegisterPage = Loader(lazy(() => import("src/content/pages/Register")));
const RequestPasswordPage = Loader(
  lazy(() => import("src/content/pages/RecoverPassword/RequestPassword"))
);
const RequestPasswordResetPage = Loader(
  lazy(() => import("src/content/pages/RecoverPassword/RequestPasswordReset"))
);

//DASBOARD
const Starter = Loader(lazy(() => import("src/content/pages/Dashboard")));

//USERS
const UsersList = Loader(lazy(() => import("src/content/pages/UsersList")));
const UserProfile = Loader(lazy(() => import("src/content/pages/UserProfile")));
const UserEdit = Loader(lazy(() => import("src/content/pages/UserEdit")));
const UserCreate = Loader(lazy(() => import("src/content/pages/UserCreate")));

//ORGANIZATIONS
const OrganizationsList = Loader(
  lazy(() => import("src/content/pages/OrganizationsList"))
);
const OrganizationEdit = Loader(
  lazy(() => import("src/content/pages/OrganizationEdit"))
);
const CreateOrganization = Loader(
  lazy(() => import("src/content/pages/OrganizationCreate"))
);

//ORDERS
const OrdersList = Loader(lazy(() => import("src/content/pages/OrdersList")));
const MyOrdersList = Loader(lazy(() => import("src/content/pages/OrdersList")));
const CreateOrder = Loader(lazy(() => import("src/content/pages/OrderCreate")));
const OrderDetails = Loader(
  lazy(() => import("src/content/pages/OrderDetail"))
);
const CreateWasteOrder = Loader(
  lazy(
    () =>
      import(
        "src/content/pages/waste-orders/create-waste-order-view/create-waste-order"
      )
  )
);

const WasteOrderView = Loader(
  lazy(
    () =>
      import("src/content/pages/waste-orders/waste-order-view/waste-order-view")
  )
);

const WasteOrdersView = Loader(
  lazy(
    () =>
      import(
        "src/content/pages/waste-orders/waste-orders-view/waste-orders-view"
      )
  )
);

const WasteOrderEditView = Loader(
  lazy(
    () =>
      import(
        "src/content/pages/waste-orders/waste-order-edit-view/waste-order-edit-view"
      )
  )
);

//RECEPTIONS
const AllReceptionList = Loader(
  lazy(() => import("src/content/pages/ReceptionList/All"))
);
const CompletedReceptionList = Loader(
  lazy(() => import("src/content/pages/ReceptionList/Completed"))
);
const PendingReceptionList = Loader(
  lazy(() => import("src/content/pages/ReceptionList/Pending"))
);
const ReceptionManagement = Loader(
  lazy(() => import("src/content/pages/ReceptionManagement/Pending"))
);
const ReceptionCompleted = Loader(
  lazy(() => import("src/content/pages/ReceptionManagement/Completed"))
);

//PRODUCTS
const ProductsList = Loader(
  lazy(() => import("src/content/pages/ProductsList"))
);
const ProductDetail = Loader(
  lazy(() => import("src/content/pages/ProductDetail"))
);
const EditProductView = Loader(
  lazy(() => import("src/content/pages/edit-product-view/edit-product-view"))
);

const ProductsClassifiedList = Loader(
  lazy(() => import("src/content/pages/ProductClassifiedList"))
);
const ProductsProductionList = Loader(
  lazy(() => import("src/content/pages/ProductsProductionList"))
);
const ProductsPackedList = Loader(
  lazy(() => import("src/content/pages/ProductsPackedList"))
);

//PROCESS
const ProcessPacked = Loader(
  lazy(() => import("src/content/pages/ProcessPacked"))
);
const ProcessCrushed = Loader(
  lazy(() => import("src/content/pages/ProcessCrushed"))
);

//ROUTES
const RoutesList = Loader(lazy(() => import("src/content/pages/RoutesList")));
const CreateRoute = Loader(lazy(() => import("src/content/pages/RouteCreate")));

const QRScaner = Loader(lazy(() => import("src/content/pages/QrScanner")));

//OPERATIONS
const Operations = Loader(lazy(() => import("src/content/pages/Operations")));
const Packaging = Loader(
  lazy(() => import("src/content/pages/Operations/Packaging"))
);
const Crushing = Loader(
  lazy(() => import("src/content/pages/Operations/Crushing"))
); //acabar
//JSM 20240516
const SackPackaging = Loader(
  lazy(() => import("src/content/pages/Operations/SackPackaging"))
); //acabar
const Classification = Loader(
  lazy(() => import("src/content/pages/Operations/Classification"))
);
const StartClassification = Loader(
  lazy(
    () =>
      import("src/content/pages/Operations/Classification/StartClassification")
  )
);
const AssignHopper = Loader(
  lazy(() => import("src/content/pages/Operations/AssignHopper"))
);
const RemoveHopper = Loader(
  lazy(() => import("src/content/pages/Operations/RemoveHopper"))
);
const JoinLots = Loader(
  lazy(() => import("src/content/pages/Operations/JoinLots"))
);
const DumpContainer = Loader(
  lazy(() => import("src/content/pages/Operations/DumpContainer"))
);
const WeighContainer = Loader(
  lazy(() => import("src/content/pages/Operations/WeighContainer"))
);
const Cutting = Loader(
  lazy(
    () =>
      import(
        "src/content/pages/Operations/cutting-processes/cutting-processes-view"
      )
  )
);
const CuttingTable = Loader(
  lazy(
    () =>
      import(
        "src/content/pages/Operations/cutting-process/cutting-process-view"
      )
  )
);
const OperationsQr = Loader(
  lazy(() => import("src/content/pages/Operations/Operations"))
);

//CONTAINERS
const ContainersList = Loader(
  lazy(() => import("src/content/pages/ContainersList"))
);
const ContainersWamList = Loader(
  lazy(() => import("src/content/pages/ContainersList"))
);

//CONFIGURATIONS
const ClassifierConfigurationsList = Loader(
  lazy(() => import("src/content/pages/ClassifierConfigurationsList"))
);

//TRACEABILITY
const TraceabilitiesList = Loader(
  lazy(() => import("src/content/pages/Traceability"))
);
const TracebilytyDetail = Loader(
  lazy(() => import("src/content/pages/TraceabilityDetail"))
);

//ADMINISTRATION
const LogisticCentersList = Loader(
  lazy(() => import("src/content/pages/LogisticCentersList"))
);
const LogisticCentersCreate = Loader(
  lazy(() => import("src/content/pages/LogisticCentersCreate"))
);
const LogisticCentersEdit = Loader(
  lazy(() => import("src/content/pages/LogisticCenterEdit"))
);
const FacilityTypesList = Loader(
  lazy(() => import("src/content/pages/FacilityTypesList"))
);
const FacilityTypesCreate = Loader(
  lazy(() => import("src/content/pages/FacilityTypesCreate"))
);
const FacilityTypesEdit = Loader(
  lazy(() => import("src/content/pages/FacilityTypesEdit"))
);
const ClasificationList = Loader(
  lazy(() => import("src/content/pages/ClasificationList"))
);
const ClasificationDetailList = Loader(
  lazy(() => import("src/content/pages/ClasificationDetail"))
);
const ScalesList = Loader(lazy(() => import("src/content/pages/ScalesList")));
const ScalesEdit = Loader(lazy(() => import("src/content/pages/ScalesEdit")));
const ScaleCreate = Loader(lazy(() => import("src/content/pages/ScaleCreate")));

const InventoryFormView = Loader(
  lazy(() => import("src/content/pages/inventory-form-view/inventory-form"))
);

const AdminView = Loader(
  lazy(() => import("src/content/pages/admin-view/admin-view"))
);

const ALL_USERS = [
  ROLE.SUPER_ADMIN,
  ROLE.ADMIN,
  ROLE.TRACEABILITY,
  ROLE.SELLING,
  ROLE.SHIPPING,
  ROLE.STORE,
  ROLE.RECEPTION,
  ROLE.TRANSFORMATION,
  ROLE.SUPER_ADMIN,
  ROLE.CLASIFICATION,
  ROLE.MOBILE_APP,
];
const routes: RouteObject[] = [
  {
    path: "admin",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <AdminView />,
      },
    ],
  },
  {
    path: "settings",
    element: <SidebarLayout />,
    children: [
      {
        path: "",
        element: <Navigate to="userprofile" replace />,
      },
      {
        path: "userprofile",
        element: (
          <PrivateRoute
            roles={[
              ROLE.SUPER_ADMIN,
              ROLE.ADMIN,
              ROLE.TRACEABILITY,
              ROLE.SELLING,
              ROLE.SHIPPING,
              ROLE.STORE,
              ROLE.RECEPTION,
              ROLE.TRANSFORMATION,
              ROLE.SUPER_ADMIN,
              ROLE.CLASIFICATION,
              ROLE.MOBILE_APP,
            ]}
            component={UserProfile}
          />
        ),
      },
    ],
  },
  {
    path: "",
    element: <BaseLayout />,
    children: [
      {
        path: "/",
        element: <LoginPage />,
      },
      {
        path: "login",
        element: <Navigate to="/" replace />,
      },
      // {
      //   path: "register",
      //   element: <RegisterPage />,
      // },
      {
        path: "recover-password",
        element: <RequestPasswordPage />,
      },
      {
        path: "recovery-password",
        element: <RequestPasswordResetPage />,
      },
    ],
  },
  {
    path: "management",
    element: <SidebarLayout />,
    children: [
      {
        path: "operations",
        element: <PrivateRoute roles={ALL_USERS} component={Operations} />,
      },
      {
        path: "inventory",
        element: (
          <PrivateRoute roles={ALL_USERS} component={InventoryFormView} />
        ),
      },
      {
        path: "users",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={UsersList}
          />
        ),
      },
      {
        path: "users/create-user",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={UserCreate}
          />
        ),
      },
      {
        path: "users/edit/:id",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={UserEdit}
          />
        ),
      },
      {
        path: "organizations",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={OrganizationsList}
          />
        ),
      },
      {
        path: "organizations/create-organization",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN]}
            component={CreateOrganization}
          />
        ),
      },
      {
        path: "organizations/edit/:id",
        element: (
          <PrivateRoute roles={ALL_USERS} component={OrganizationEdit} />
        ),
      },
      {
        path: "orders",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
            component={OrdersList}
          />
        ),
      },
      {
        path: "my-orders",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
            component={MyOrdersList}
          />
        ),
      },
      {
        path: "orders/create-order",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
            component={CreateOrder}
          />
        ),
      },
      {
        path: "orders/details/:id",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
            component={OrderDetails}
          />
        ),
      },
      {
        path: "waste-orders/create",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
            component={CreateWasteOrder}
          />
        ),
      },
      {
        path: "waste-orders",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
            component={WasteOrdersView}
          />
        ),
      },
      {
        path: "waste-orders/:id",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
            component={WasteOrderView}
          />
        ),
      },
      {
        path: "waste-orders/:id/edit",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
            component={WasteOrderEditView}
          />
        ),
      },
      /*       {
        path: 'routes/',
        element: <PrivateRoute roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]} component={RoutesList} />
      }, */
      {
        path: "routes/create-route",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN, ROLE.SHIPPING]}
            component={CreateRoute}
          />
        ),
      },
      {
        path: "receptions/all",
        element: (
          <PrivateRoute roles={ALL_USERS} component={AllReceptionList} />
        ),
      },
      {
        path: "receptions/completed",
        element: (
          <PrivateRoute roles={ALL_USERS} component={CompletedReceptionList} />
        ),
      },
      {
        path: "receptions/pending",
        element: (
          <PrivateRoute roles={ALL_USERS} component={PendingReceptionList} />
        ),
      },
      {
        path: "receptions/noShipment",
        element: (
          <PrivateRoute roles={ALL_USERS} component={ReceptionNoShipment} />
        ),
      },
      {
        path: "receptions/manage-reception/:id",
        element: (
          <PrivateRoute roles={ALL_USERS} component={ReceptionManagement} />
        ),
      },
      {
        path: "receptions/completed-reception/:id",
        element: (
          <PrivateRoute roles={ALL_USERS} component={ReceptionCompleted} />
        ),
      },
      {
        path: "lots/all",
        element: <PrivateRoute roles={ALL_USERS} component={ProductsList} />,
      },
      {
        path: "lots/clasification",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={ProductsClassifiedList}
          />
        ),
      },
      {
        path: "lots/production",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={ProductsProductionList}
          />
        ),
      },
      {
        path: "lots/packed",
        element: <PrivateRoute roles={ALL_USERS} component={ProcessPacked} />,
      },
      {
        path: "lots/crushed",
        element: <PrivateRoute roles={ALL_USERS} component={ProcessCrushed} />,
      },
      {
        path: "lots/details/:id",
        element: <PrivateRoute roles={ALL_USERS} component={ProductDetail} />,
      },
      {
        path: "lots/details/:id/edit",
        element: <PrivateRoute roles={ALL_USERS} component={EditProductView} />,
      },
      {
        path: "process/packed",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={ProcessPacked}
          />
        ),
      },
      {
        path: "process/classification",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={Classification}
          />
        ),
      },
      {
        path: "process/operations",
        element: <PrivateRoute roles={ALL_USERS} component={OperationsQr} />,
      },
      {
        path: "qr-scanner",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={QRScaner}
          />
        ),
      },
      {
        path: "traceability/",
        element: (
          <PrivateRoute roles={ALL_USERS} component={TraceabilitiesList} />
        ),
      },
      {
        path: "traceability/:id",
        element: (
          <PrivateRoute roles={ALL_USERS} component={TracebilytyDetail} />
        ),
      },
      {
        path: "containers",
        element: (
          <PrivateRoute roles={ALL_USERS} component={ContainersWamList} />
        ),
      },
      {
        path: "operations",
        element: <PrivateRoute roles={ALL_USERS} component={Operations} />,
      },
      {
        path: "operations/packaging",
        element: <PrivateRoute roles={ALL_USERS} component={Packaging} />,
      },
      {
        path: "operations/crushing",
        element: <PrivateRoute roles={ALL_USERS} component={Crushing} />,
      },
      //JSM 20240516
      {
        path: "operations/sackpackaging",
        element: <PrivateRoute roles={ALL_USERS} component={SackPackaging} />,
      },
      {
        path: "operations/classification",
        element: <PrivateRoute roles={ALL_USERS} component={Classification} />,
      },
      {
        path: "operations/startClassification",
        element: (
          <PrivateRoute roles={ALL_USERS} component={StartClassification} />
        ),
      },
      {
        path: "operations/assign-hopper",
        element: <PrivateRoute roles={ALL_USERS} component={AssignHopper} />,
      },
      {
        path: "operations/remove-hopper",
        element: <PrivateRoute roles={ALL_USERS} component={RemoveHopper} />,
      },
      {
        path: "operations/join-lots",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={JoinLots}
          />
        ),
      },
      {
        path: "operations/dump-container",
        element: <PrivateRoute roles={ALL_USERS} component={DumpContainer} />,
      },
      {
        path: "operations/weigh-container",
        element: <PrivateRoute roles={ALL_USERS} component={WeighContainer} />,
      },
      {
        path: "operations/cutting",
        element: <PrivateRoute roles={ALL_USERS} component={Cutting} />,
      },
      {
        path: "operations/cutting/:id",
        element: <PrivateRoute roles={ALL_USERS} component={CuttingTable} />,
      },
      {
        path: "administration/scales",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={ScalesList}
          />
        ),
      },
      {
        path: "administration/classifier",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={ClassifierConfigurationsList}
          />
        ),
      },
      {
        path: "administration/container",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={ContainersList}
          />
        ),
      },
      {
        path: "administration/logistic-centers",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={LogisticCentersList}
          />
        ),
      },
      {
        path: "administration/logistic-centers/create",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={LogisticCentersCreate}
          />
        ),
      },
      {
        path: "administration/logistic-centers/edit/:id",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={LogisticCentersEdit}
          />
        ),
      },
      {
        path: "administration/facility-types",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={FacilityTypesList}
          />
        ),
      },
      {
        path: "administration/facility-types/create",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={FacilityTypesCreate}
          />
        ),
      },
      {
        path: "administration/facility-types/edit/:id",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={FacilityTypesEdit}
          />
        ),
      },
      {
        path: "administration/dashboard",
        element: <PrivateRoute roles={ALL_USERS} component={Starter} />,
      },
      {
        path: "administration/clasification",
        element: (
          <PrivateRoute roles={ALL_USERS} component={ClasificationList} />
        ),
      },
      {
        path: "administration/clasification/recipe/:id",
        element: (
          <PrivateRoute roles={ALL_USERS} component={ClasificationDetailList} />
        ),
      },
      {
        path: "administration/scales",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={ScalesList}
          />
        ),
      },
      {
        path: "administration/scales/edit/:id",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={ScalesEdit}
          />
        ),
      },
      {
        path: "administration/scales/create-scale",
        element: (
          <PrivateRoute
            roles={[ROLE.SUPER_ADMIN, ROLE.ADMIN]}
            component={ScaleCreate}
          />
        ),
      },
    ],
  },
];

export default routes;
