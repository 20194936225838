import { Box, CircularProgress } from '@mui/material';
import React, { FC } from 'react';

interface IProps {
  isLoading: boolean;
  size?: number;
}

export const Loading: FC<IProps> = (props) => {
  const { isLoading, size } = props;

  if (!isLoading) return <React.Fragment />;

  return (
    <Box
      sx={{
        minHeight: '50vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      <CircularProgress size={size ?? 64} disableShrink thickness={3} />
    </Box>
  );
};
