import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReAuth } from "./baseQuery";

export interface SackRequest {
    product_id: number;
    container_id: number;
    weight?: number;
}

export interface SackResponse {
    remaining_waste: number;
}

export const operationsApi = createApi({
    baseQuery: baseQueryWithReAuth,
    reducerPath: 'operations',
    endpoints: (builder) => ({
        assignWasteOrderLineProductToContainer: builder.mutation<SackResponse, SackRequest>({
            query: body => ({
                url: 'api/operations/sack',
                method: 'POST',
                body
            }),
        }),
    }),
});

export const {
    useAssignWasteOrderLineProductToContainerMutation
} = operationsApi;