import { createSlice } from "@reduxjs/toolkit";
import { User } from '../../models/users';
import { apiUsers } from "src/api/users";

type ConfirmedUser = {
    user: User | null
    message: string | null
}

type UsersState = {
    users: User[] | null
    usersByOrg: User[] | null
    userById: User | null
    userCreatedMessage: ConfirmedUser
    userUpdatedMessage: ConfirmedUser
    userDeletedMessage: ConfirmedUser
    selectedUserOrganization: number
}

const DEFAULT_STATE: UsersState = {
    users: null,
    usersByOrg: null,
    userById:  null,
    userCreatedMessage: null,
    userUpdatedMessage: null,
    userDeletedMessage: null,
    selectedUserOrganization: null,
}

export const userSlice = createSlice({
    name: 'users',
    initialState: DEFAULT_STATE,
    reducers: {
        setSelectedUserOrganization: (state, action) => {
          if (action.payload === state.selectedUserOrganization) {
            state.selectedUserOrganization = null
          }
          else state.selectedUserOrganization = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
          apiUsers.endpoints.create.matchFulfilled,
          (state, { payload: { user, message } }) => {
            state.userCreatedMessage = { user, message }
          }
        )
        builder.addMatcher(
            apiUsers.endpoints.getById.matchFulfilled,
            (state, { payload: {user} }) => {
                state.userById = user
            },
          )
          builder.addMatcher(
            apiUsers.endpoints.update.matchFulfilled,
            (state, { payload: user }) => {
              state.userUpdatedMessage = { user, message: 'User updated' }
            },
          )
      },
});

export default userSlice.reducer;
export const { setSelectedUserOrganization } = userSlice.actions;