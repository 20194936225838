import { Delete } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

interface IProps {
  materials: { name: string; id: number }[];
  percentages: number[];
  handleDeleteMaterial: Function;
  isOnlyRead: boolean;
}

function AddedMaterials(props: IProps) {
  const { materials, percentages, handleDeleteMaterial, isOnlyRead } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const MATERIAL_OTROS = 1; //JSM20240314 abans 12
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      mt={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {materials?.map((material, index) => (
        <Grid container rowSpacing={3} key={index}>
          <Grid item xs={10} sm={5}>
            <Box
              sx={{
                display: "flex",
                flexDirection: isSmallScreen ? "column" : "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                name="material"
                label={t("Material")}
                sx={{ m: 1, minWidth: "150px" }}
                fullWidth
                disabled
                value={material.name + " " + percentages[index] + "%"}
              />
            </Box>
          </Grid>
          {material.id !== MATERIAL_OTROS && !isOnlyRead && (
            <Grid
              item
              xs={2}
              sm={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Tooltip
                title={t("delete")}
                arrow
                onClick={() => {
                  handleDeleteMaterial(index);
                }}
              >
                <IconButton
                  sx={{
                    color: theme.palette.error.main,
                    background: theme.colors.error.lighter,
                    m: 1,
                  }}
                  color="inherit"
                  size="small"
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      ))}
    </Box>
  );
}

export default AddedMaterials;
