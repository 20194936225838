import ArchiveIcon from "@mui/icons-material/Archive";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import {
  Box,
  Button,
  Collapse,
  List,
  ListItem,
  alpha,
  styled,
} from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink as RouterLink } from "react-router-dom";
import { SidebarContext } from "src/contexts/SidebarContext";
import { useAppSelector } from "src/hooks/store";
import { PLAYER_TYPE, ROLE } from "src/models/roles";

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }

        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(["color"])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }

          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  "transform",
                  "opacity",
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const authInfo = useAppSelector((state) => state.auth.user);
  const roles = authInfo?.role_type_permission.map((role) => role.role);
  const player_type = authInfo?.role_type_permission.map(
    (role) => role.player_type
  );
  const organization_id = authInfo?.organization_id;
  const { t } = useTranslation();
  const { closeSidebar } = useContext(SidebarContext);

  const [openReceptions, setOpenReceptions] = useState(false);
  const [openOperations, setOpenOperations] = useState(false);
  const [openOrders, setOpenOrders] = useState(false);
  const [openLots, setOpenLots] = useState(false);
  const [openAdministration, setOpenAdministration] = useState(false);
  const [openMyOrganization, setOpenMyOrganization] = useState(false);

  const handleClickReceptions = () => {
    setOpenReceptions(!openReceptions);
  };

  const handleClickOperations = () => {
    setOpenOperations(!openOperations);
  };

  const handleClickOrders = () => {
    setOpenOrders(!openOrders);
  };

  const handleClickLots = () => {
    setOpenLots(!openLots);
  };

  const handleClickAdministration = () => {
    setOpenAdministration(!openAdministration);
  };

  const handleClickMyOrganization = () => {
    setOpenMyOrganization(!openMyOrganization);
  };

  const adminRoles = [ROLE.ADMIN];
  const superAdminRoles = [ROLE.SUPER_ADMIN];
  const userRoles = [
    ROLE.CLASIFICATION,
    ROLE.MOBILE_APP,
    ROLE.RECEPTION,
    ROLE.SELLING,
    ROLE.SHIPPING,
    ROLE.STORE,
    ROLE.TRACEABILITY,
    ROLE.TRANSFORMATION,
  ];
  const playerTypes = [PLAYER_TYPE.RET];
  const WAM = [PLAYER_TYPE.WAM];
  const WAP = [PLAYER_TYPE.WAP];
  const FIB = [PLAYER_TYPE.FIB];
  const TRA = [PLAYER_TYPE.TRA, PLAYER_TYPE.WAPGM];

  return (
    <>
      {/* MENU DE SUPERADMIN */}
      {roles?.some((userRole) => superAdminRoles.includes(userRole)) && (
        <MenuWrapper>
          <List component="div">
            {/* BOTÓN INICIO */}
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/management/administration/dashboard"
                    startIcon={<HomeIcon />}
                  >
                    {t("start")}
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
            {/* BOTÓN OPERACIONES */}
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/management/operations"
                    startIcon={<HomeIcon />}
                  >
                    {t("operationsWAM")}
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
            {/* MENÚ ENVÍOS */}
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div" onClick={() => handleClickOrders()}>
                  <Button startIcon={<ArchiveIcon />}>
                    {t("orders")}
                    {openOrders ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>

            <SubMenuWrapper>
              <Collapse in={openOrders} timeout="auto" unmountOnExit>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      component={RouterLink}
                      to="/management/orders"
                      onClick={closeSidebar}
                    >
                      {t("allOrders")}
                    </Button>
                  </ListItem>
                </List>
              </Collapse>
            </SubMenuWrapper>

            {/* MENÚ RECEPCIONES */}
            <SubMenuWrapper>
              <List component="div">
                <ListItem
                  component="div"
                  onClick={() => handleClickReceptions()}
                >
                  <Button startIcon={<ArchiveIcon />}>
                    {t("receptions")}
                    {openReceptions ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>

            <SubMenuWrapper>
              <Collapse in={openReceptions} timeout="auto" unmountOnExit>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      component={RouterLink}
                      to="/management/receptions/all"
                      onClick={closeSidebar}
                    >
                      {t("pendingReceptionsOfMyOrganization")}
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      component={RouterLink}
                      to="/management/receptions/completed"
                      onClick={closeSidebar}
                    >
                      {t("completedReceptionsOfMyOrganization")}
                    </Button>
                  </ListItem>
                </List>
              </Collapse>
            </SubMenuWrapper>
            <SubMenuWrapper>
              <Collapse in={openReceptions} timeout="auto" unmountOnExit>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      component={RouterLink}
                      to="/management/receptions/noShipment"
                      onClick={closeSidebar}
                    >
                      {t("receptionWithoutSending")}
                    </Button>
                  </ListItem>
                </List>
              </Collapse>
            </SubMenuWrapper>

            {/* MENÚ LOTES */}
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div" onClick={() => handleClickLots()}>
                  <Button startIcon={<ArchiveIcon />}>
                    {t("virtualWarehouse")}
                    {openLots ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>

            <SubMenuWrapper>
              <Collapse in={openLots} timeout="auto" unmountOnExit>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      component={RouterLink}
                      to="/management/lots/all"
                      onClick={closeSidebar}
                    >
                      {t("organizationWarehouse")}
                    </Button>
                  </ListItem>
                </List>
              </Collapse>
            </SubMenuWrapper>

            {/* MENÚ ADMINISTRACIÓN */}
            <SubMenuWrapper>
              <List component="div">
                <ListItem
                  component="div"
                  onClick={() => handleClickAdministration()}
                >
                  <Button startIcon={<ArchiveIcon />}>
                    {t("administration")}
                    {openAdministration ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>

            <SubMenuWrapper>
              <Collapse in={openAdministration} timeout="auto" unmountOnExit>
                <List component="div">
                  <ListItem component="div">
                    <Button
                      component={RouterLink}
                      to="/management/organizations"
                      onClick={closeSidebar}
                    >
                      {t("manageOrganizations")}
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      component={RouterLink}
                      to="/management/users"
                      onClick={closeSidebar}
                    >
                      {t("manageUsers")}
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      component={RouterLink}
                      to="/management/administration/clasification"
                      onClick={closeSidebar}
                    >
                      {t("manageClasification")}
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      component={RouterLink}
                      to="/management/administration/container"
                      onClick={closeSidebar}
                    >
                      {t("manageBuckets")}
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      component={RouterLink}
                      to="/management/administration/logistic-centers"
                      onClick={closeSidebar}
                    >
                      {t("manageLogisticsCenters")}
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      component={RouterLink}
                      to="/management/administration/facility-types"
                      onClick={closeSidebar}
                    >
                      {t("manageFacilityWaste")}
                    </Button>
                  </ListItem>
                  <ListItem component="div">
                    <Button
                      component={RouterLink}
                      to="/management/administration/scales"
                      onClick={closeSidebar}
                    >
                      {t("manageScales")}
                    </Button>
                  </ListItem>
                </List>
              </Collapse>
            </SubMenuWrapper>
          </List>
        </MenuWrapper>
      )}

      {/* MENU DE ADMIN */}
      {roles?.some((userRole) => adminRoles.includes(userRole)) && (
        <MenuWrapper>
          <List component="div">
            {/* BOTÓN INICIO */}
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/management/administration/dashboard"
                    startIcon={<HomeIcon />}
                  >
                    {t("start")}
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>

            {/*MENU DEL WAM ADMIN*/}
            {player_type.some((playerType) => WAM.includes(playerType)) && (
              <>
                <SubMenuWrapper>
                  <List component="div">
                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/management/operations"
                        startIcon={<HomeIcon />}
                      >
                        {t("operationsWAM")}
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <List component="div">
                    <ListItem
                      component="div"
                      onClick={() => handleClickOrders()}
                    >
                      <Button startIcon={<ArchiveIcon />}>
                        {t("orders")}
                        {openOrders ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <Collapse in={openOrders} timeout="auto" unmountOnExit>
                    <List component="div">
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/orders"
                          onClick={closeSidebar}
                        >
                          {t("ordersOfMyOrganization")}
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                </SubMenuWrapper>
                <SubMenuWrapper>
                  <List component="div">
                    <ListItem
                      component="div"
                      onClick={() => handleClickReceptions()}
                    >
                      <Button startIcon={<ArchiveIcon />}>
                        {t("receptions")}
                        {openReceptions ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <Collapse in={openReceptions} timeout="auto" unmountOnExit>
                    <List component="div">
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/receptions/pending"
                          onClick={closeSidebar}
                        >
                          {t("pendingReceptionsOfMyCenter")}
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/receptions/completed"
                          onClick={closeSidebar}
                        >
                          {t("completedReceptionsOfMyCenter")}
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/receptions/noShipment"
                          onClick={closeSidebar}
                        >
                          {t("receptionWithoutSending")}
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <List component="div">
                    <ListItem component="div" onClick={() => handleClickLots()}>
                      <Button startIcon={<ArchiveIcon />}>
                        {/* {t("virtualWarehouse")} //JSM 20240417 */}
                        {t("warehouse")}
                        {openLots ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <Collapse in={openLots} timeout="auto" unmountOnExit>
                    <List component="div">
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/lots/all"
                          onClick={closeSidebar}
                        >
                          {t("centerWarehouse")}
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/containers"
                          onClick={closeSidebar}
                        >
                          {t("organizationContainers")}
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <List component="div">
                    <ListItem
                      component="div"
                      onClick={() => handleClickMyOrganization()}
                    >
                      <Button startIcon={<ArchiveIcon />}>
                        {t("myOrganization")}
                        {openMyOrganization ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <Collapse
                    in={openMyOrganization}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div">
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to={`organizations/edit/${organization_id}`}
                          onClick={closeSidebar}
                        >
                          {t("myOrganization")}
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/users"
                          onClick={closeSidebar}
                        >
                          {t("myUsers")}
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/administration/logistic-centers"
                          onClick={closeSidebar}
                        >
                          {t("myLogisticsCenters")}
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/administration/facility-types"
                          onClick={closeSidebar}
                        >
                          {t("myFacilityWaste")}
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                </SubMenuWrapper>
              </>
            )}

            {/*MENU DE WAP ADMIN*/}
            {player_type.some((playerType) => WAP.includes(playerType)) && (
              <>
                <SubMenuWrapper>
                  <List component="div">
                    <ListItem
                      component="div"
                      onClick={() => handleClickOrders()}
                    >
                      <Button startIcon={<ArchiveIcon />}>
                        {t("orders")}
                        {openOrders ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <Collapse in={openOrders} timeout="auto" unmountOnExit>
                    <List component="div">
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/orders"
                          onClick={closeSidebar}
                        >
                          {t("ordersOfMyOrganization")}
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <List component="div">
                    <ListItem
                      component="div"
                      onClick={() => handleClickMyOrganization()}
                    >
                      <Button startIcon={<ArchiveIcon />}>
                        {t("myOrganization")}
                        {openMyOrganization ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <Collapse
                    in={openMyOrganization}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div">
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to={`organizations/edit/${organization_id}`}
                          onClick={closeSidebar}
                        >
                          {t("myOrganization")}
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/users"
                          onClick={closeSidebar}
                        >
                          {t("myUsers")}
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/administration/logistic-centers"
                          onClick={closeSidebar}
                        >
                          {t("myLogisticsCenters")}
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/administration/facility-types"
                          onClick={closeSidebar}
                        >
                          {t("myFacilityWaste")}
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                </SubMenuWrapper>
              </>
            )}
          </List>
        </MenuWrapper>
      )}

      {/* MENU DE USERS */}
      {roles?.some((userRole) => userRoles.includes(userRole)) && (
        <MenuWrapper>
          <List component="div">
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/management/administration/dashboard"
                    startIcon={<HomeIcon />}
                  >
                    {t("start")}
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>

            {/*MENU DEL WAM USER*/}
            {player_type.some((playerType) => WAM.includes(playerType)) && (
              <>
                <SubMenuWrapper>
                  <List component="div">
                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/management/operations"
                        startIcon={<HomeIcon />}
                      >
                        {t("operationsWAM")}
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <List component="div">
                    <ListItem
                      component="div"
                      onClick={() => handleClickOrders()}
                    >
                      <Button startIcon={<ArchiveIcon />}>
                        {t("orders")}
                        {openOrders ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <Collapse in={openOrders} timeout="auto" unmountOnExit>
                    <List component="div">
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/orders"
                          onClick={closeSidebar}
                        >
                          {t("myOrders")}
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                </SubMenuWrapper>
                <SubMenuWrapper>
                  <List component="div">
                    <ListItem
                      component="div"
                      onClick={() => handleClickReceptions()}
                    >
                      <Button startIcon={<ArchiveIcon />}>
                        {t("receptions")}
                        {openReceptions ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <Collapse in={openReceptions} timeout="auto" unmountOnExit>
                    <List component="div">
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/receptions/pending"
                          onClick={closeSidebar}
                        >
                          {t("pendingReceptionsOfMyCenter")}
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/receptions/completed"
                          onClick={closeSidebar}
                        >
                          {t("completedReceptionsOfMyCenter")}
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/receptions/noShipment"
                          onClick={closeSidebar}
                        >
                          {t("receptionWithoutSending")}
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <List component="div">
                    <ListItem component="div" onClick={() => handleClickLots()}>
                      <Button startIcon={<ArchiveIcon />}>
                        {/* {t("virtualWarehouse")} //JSM 20240417 */}
                        {t("warehouse")}
                        {openLots ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <Collapse in={openLots} timeout="auto" unmountOnExit>
                    <List component="div">
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/lots/all"
                          onClick={closeSidebar}
                        >
                          {t("centerWarehouse")}
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/containers"
                          onClick={closeSidebar}
                        >
                          {t("myContainers")}
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                </SubMenuWrapper>
              </>
            )}
            {/* MENU DEL FIB USER */}
            {player_type.some((playerType) => FIB.includes(playerType)) && (
              <>
                <SubMenuWrapper>
                  <List component="div">
                    <ListItem component="div">
                      <Button
                        disableRipple
                        component={RouterLink}
                        onClick={closeSidebar}
                        to="/management/operations"
                        startIcon={<HomeIcon />}
                      >
                        {t("operationsWAM")}
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>
                <SubMenuWrapper>
                  <List component="div">
                    <ListItem
                      component="div"
                      onClick={() => handleClickOrders()}
                    >
                      <Button startIcon={<ArchiveIcon />}>
                        {t("orders")}
                        {openOrders ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <Collapse in={openOrders} timeout="auto" unmountOnExit>
                    <List component="div">
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/orders"
                          onClick={closeSidebar}
                        >
                          {t("myOrders")}
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                </SubMenuWrapper>
                <SubMenuWrapper>
                  <List component="div">
                    <ListItem
                      component="div"
                      onClick={() => handleClickReceptions()}
                    >
                      <Button startIcon={<ArchiveIcon />}>
                        {t("receptions")}
                        {openReceptions ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <Collapse in={openReceptions} timeout="auto" unmountOnExit>
                    <List component="div">
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/receptions/pending"
                          onClick={closeSidebar}
                        >
                          {t("pendingReceptionsOfMyCenter")}
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/receptions/completed"
                          onClick={closeSidebar}
                        >
                          {t("completedReceptionsOfMyCenter")}
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/receptions/noShipment"
                          onClick={closeSidebar}
                        >
                          {t("receptionWithoutSending")}
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                </SubMenuWrapper>
                <SubMenuWrapper>
                  <List component="div">
                    <ListItem component="div" onClick={() => handleClickLots()}>
                      <Button startIcon={<ArchiveIcon />}>
                        {t("virtualWarehouse")}
                        {openLots ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <Collapse in={openLots} timeout="auto" unmountOnExit>
                    <List component="div">
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/lots/all"
                          onClick={closeSidebar}
                        >
                          {t("myWarehouse")}
                        </Button>
                      </ListItem>
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/containers"
                          onClick={closeSidebar}
                        >
                          {t("myContainers")}
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                </SubMenuWrapper>
              </>
            )}

            {/*MENU DEL WAP USER*/}
            {player_type.some((playerType) => WAP.includes(playerType)) && (
              <>
                <SubMenuWrapper>
                  <List component="div">
                    <ListItem
                      component="div"
                      onClick={() => handleClickOrders()}
                    >
                      <Button startIcon={<ArchiveIcon />}>
                        {t("orders")}
                        {openOrders ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </Button>
                    </ListItem>
                  </List>
                </SubMenuWrapper>

                <SubMenuWrapper>
                  <Collapse in={openOrders} timeout="auto" unmountOnExit>
                    <List component="div">
                      <ListItem component="div">
                        <Button
                          component={RouterLink}
                          to="/management/orders"
                          onClick={closeSidebar}
                        >
                          {t("myOrders")}
                        </Button>
                      </ListItem>
                    </List>
                  </Collapse>
                </SubMenuWrapper>
              </>
            )}
          </List>
        </MenuWrapper>
      )}

      {/* MENU PEDIDOS DE RESIDUO */}
      {player_type?.some((userRole) => {
        return TRA.includes(userRole);
      }) && (
        <SubMenuWrapper>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/management/waste-orders"
                startIcon={<ArchiveIcon />}
              >
                Pedidos de residuo
              </Button>
            </ListItem>
          </List>
        </SubMenuWrapper>
      )}
    </>
  );
}

export default SidebarMenu;
