export function returnLerCode(wasteOrigin: number, rejectionMotive: number) {
  if(wasteOrigin === 1 && (rejectionMotive === 1 || rejectionMotive === 2 || rejectionMotive === 3 || rejectionMotive === 4 || rejectionMotive === 5 || rejectionMotive === 6 || rejectionMotive === 7 || rejectionMotive === 8)) {
    return "200110";
  }
  else if(wasteOrigin === 1 && rejectionMotive === 9) {
    return null;
  }
  else if(wasteOrigin === 2){
    return "200110";
  }
  else if(wasteOrigin === 3){
    return "040109";
  }
  else return null;
}
