import {
  Autocomplete,
  Checkbox,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { AssignContainerZone } from "../Pending/components/AssignContainerZone";
import { useTranslation } from "react-i18next";
import {
  IContainer,
  IContainerDataGridRow,
  IContainer_ID,
} from "src/models/container";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

export default function renderInputs(
  formFields,
  t,
  setColor,  color,
  setComposition,  composition,
  setClosedCirculation,  closedCirculation,
  waste_type,  setWasteType,
  series,  setSeries,
  reference,  setReference,
  quantity,  setQuantity,
  weight,  setWeight,
  pallets, setPallets,
  numPackage,  setNumPackage,
  description,  setDescription,
  wasteOrigin,  setWasteOrigin,
  rejectionMotive,  setRejectionMotive,
  lerCode,  setLerCode,
  origin,  setOrigin,
  setDestination,  selectedContainers,
  setSelectedContainers,
  player_type, userEmail
) {
  //console.log("formFields", formFields);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const handleContainersSelection = (
    containers: IContainer[],
    values: IContainer_ID[]
  ) => {
    const _containers: IContainerDataGridRow[] = containers
      .filter((container) => {
        return values.includes(container.id);
      })
      .map((container) => {
        return {
          containerID: container.id,
          containerName: container.reference,
          containerWeight: container.weight,
          containerZoneID: null,
          containerZoneName: null,
        };
      });

    setSelectedContainers(_containers);
  };

  return formFields.map((field) => {
    if (field.type === "title") {
      return (
        <Grid item xs={12} sm={12} key={field.id}>
          <Typography variant="h4" component="h4">
            {field.label}
          </Typography>
        </Grid>
      );
    } else if (field.type === "select" && field.name === "container") {
      if (field.items === undefined) return <React.Fragment key={field.id} />;

      return (
        <Grid key={field.id} item xs={12} sm={field.sm}>
          {field.isVisible && (
            <FormControl fullWidth variant="outlined">
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={field.items as IContainer[]}
                value={field.items.filter((item) => {
                  //aqui ja ha entrat a coses de react rares
                  return selectedContainers
                    .map((container) => {
                      return container.containerID;
                    })
                    .includes(item.id);
                })}
                disableCloseOnSelect
                getOptionLabel={(option: IContainer) => option.reference}
                onChange={(event, value: IContainer[]) => {
                  handleContainersSelection(
                    field.items,
                    value.map((v) => v.id)
                  );
                }}
                renderOption={(props, option: IContainer, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.reference}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label={t("containersSelected")} />
                )}
              />
            </FormControl>
          )}

          <AssignContainerZone
            selectedContainers={selectedContainers}
            setSelectedContainers={setSelectedContainers}
            isOnlyRead={true}
          />
        </Grid>
      );
    } else {
      return (
        <Grid item xs={field.xs} sm={field.sm} key={field.label}>
          {field.type === "select" && field.name !== "container" ? (
            <TextField
              fullWidth
              // value={
              //   field.name === "color_id"
              //     ? color
              //     : field.name === "composition_id"
              //       ? composition
              //       : field.name === "series"
              //         ? Number(series) === 0
              //           ? 0
              //           : series || ""
              //         : field.name === "closed_circulation"
              //           ? Number(closedCirculation) === 0
              //             ? 0
              //             : closedCirculation || ""
              //           : field.name === "waste_origin"
              //             ? wasteOrigin
              //             : field.name === "rejection_motive"
              //               ? rejectionMotive
              //               : field.name === "logistic_center_id"
              //                 ? origin
              //                 : field.name === "waste_management_facility"
              //                   ? Number(field.defaultValue) === 0
              //                     ? 0
              //                     : field.defaultValue || ""
              //                   : undefined
              // }
              // onChange={(e) =>
              //   field.name === "color_id"
              //     ? setColor(e.target.value)
              //     : field.name === "composition_id"
              //       ? setComposition(e.target.value)
              //       : field.name === "series"
              //         ? setSeries(e.target.value)
              //         : field.name === "waste_origin"
              //           ? (setWasteOrigin(e.target.value),
              //             setRejectionMotive(null))
              //           : field.name === "rejection_motive"
              //             ? setRejectionMotive(e.target.value)
              //             : field.name === "logistic_center_id"
              //               ? setOrigin(e.target.value)
              //               : field.name === "waste_management_facility"
              //                 ? setDestination(e.target.value)
              //                 : setClosedCirculation(e.target.value)
              // }
              value={((field) => {
                switch (field.name) {
                  case "color_id":
                    return color;
                  case "composition_id":
                    return composition;
                  case "series":
                    return Number(series) === 0 ? 0 : series || "";
                  case "closed_circulation":
                    return closedCirculation === null ? '' : closedCirculation;
                  case "waste_origin":
                    return wasteOrigin;
                  case "rejection_motive":
                    return rejectionMotive;
                  case "logistic_center_id":
                    return origin;
                  case "waste_management_facility":
                    return Number(field.defaultValue) === 0 ? 0 : field.defaultValue || "";
                  default:
                    return undefined;
                }
              })(field)}
              onChange={(e) => {
                const field = e.target;
                switch (field.name) {
                  case "color_id":
                    setColor(field.value);
                    break;
                  case "composition_id":
                    setComposition(field.value);
                    break;
                  case "series":
                    setSeries(field.value);
                    break;
                  case "waste_origin":
                    setWasteOrigin(field.value);
                    setRejectionMotive(null);
                    break;
                  case "rejection_motive":
                    setRejectionMotive(field.value);
                    break;
                  case "logistic_center_id":
                    setOrigin(field.value);
                    break;
                  case "waste_management_facility":
                    setDestination(field.value);
                    break;
                  case "closed_circulation":
                    setClosedCirculation(field.value);
                    break;
                  default:
                    break;
                }
              }}

              label={field.label}
              name={field.name}
              type={field.type}
              variant="outlined"
              required={field.required} //JSM 20240508
              // required={(() => {
              //   switch (field.name) {
              //     case "rejection_motive":
              //       return wasteOrigin !== 1;
              //     case "series":
              //       return wasteOrigin === 3;
              //     case "color_id":
              //       return false;
              //     default:
              //       return true;
              //   }
              // })()}

              // disabled={
              //   (field.name === "color_id" && series === 0) ||
              //   field.name === "waste_management_facility"
              // }
              // select={field.type === "select"}
              // sx={
              //   (field.name === "color_id" && series !== 1) ||
              //   (field.name === "rejection_motive" && wasteOrigin != 1) ||
              //   (field.name === "series" && wasteOrigin == 3) ||
              //   (field.name === "color_id" && wasteOrigin == 3)
              //     ? { display: "none" }
              //     : {}
              // }
              disabled={(() => {
                switch (field.name) {
                  // case "color_id":
                  //   return series === 0;
                  case "waste_management_facility":
                    return true;
                  default:
                    return field.disable || false;
                }
              })()}
              select={field.type === "select"}
              sx={(() => {
                switch (field.name) {
                  // case "color_id": //JSM 20240508
                  //   return series !== 1 ? { display: "none" } : {};
                  case "rejection_motive":
                    return (userEmail != "integer.admin@integer.com") ? { display: "none" } : {};
                  // case "series":
                  //   return wasteOrigin === 3 ? { display: "none" } : {};
                  // case "color_id":
                  //   return wasteOrigin === 3 ? { display: "none" } : {};
                  // ["WAP"].includes(player_type)
                  default:
                    return {};
                }
              })()}
            >
              {field.items?.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {field.name === "color_id" && (
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: item.color,
                          marginRight: "10px",
                          borderRadius: "10%",
                        }}
                      ></div>
                    )}
                    <span>{item.label}</span>
                  </div>
                </MenuItem>
              ))}
            </TextField>
          ) : field.type === "file" ? (
            <TextField
              fullWidth
              label={field.label}
              name={field.name}
              type={field.type}
              variant="outlined"
              required
              InputLabelProps={{ shrink: true }}
            />
          ) : field.type === "number" ? (
            <TextField
              fullWidth
              label={field.label}
              name={field.name}
              type={field.type}
              variant="outlined"
              required={field.required !== undefined ? field.required : true}
              inputProps={field.name === "weight" ? { min: "0", step: "0.01" } : { min: "0" }}
              // value={
              //   field.name === "quantity"
              //     ? quantity
              //     : field.name === "weight"
              //       ? weight
              //       : numPackage
              // }
              //JSM 20240508
              value={
                (() => {
                  switch (field.name) {
                    case "quantity":
                      return quantity;
                    case "weight":
                      return weight;
                    case "pallets":
                      return pallets;
                    case "num_package":
                      return numPackage;
                    default:
                      return "0";
                  }
                })()
              }

              // onChange={(e) =>
              //   field.name === "quantity"
              //     ? setQuantity(e.target.value)
              //     : field.name === "weight"
              //       ? setWeight(e.target.value)
              //       : setNumPackage(e.target.value)
              // }
              onChange={(e) => {
                const { name, value } = e.target;
                switch (name) {
                  case "quantity":
                    setQuantity(value);
                    break;
                  case "weight":
                    setWeight(value);
                    break;
                  case "pallets":
                    setPallets(value);
                    break;
                  case "num_package":
                    setNumPackage(value);
                    break;
                }
              }}

            />
          ) : (
            <TextField
              fullWidth
              label={field.label}
              name={field.name}
              type={field.type}
              variant="outlined"
              InputLabelProps={field.name === "ler_code" ? { shrink: true } : {}}
              sx={(() => {
                switch (field.name) {
                  case "ler_code":
                    return (userEmail != "integer.admin@integer.com") ? { display: "none" } : {};
                  default:
                    return {};
                }
              })()}
              // required={field.required && !(field.name === "ler_code" && rejectionMotive === 9)}
              required={field.required} //JSM 20240508

              disabled={field.name === "waste_type"}
              value={
                field.name === "model_reference"
                  ? reference
                  : field.name === "waste_type"
                    ? waste_type?.name
                    : field.name === "ler_code"
                      ? lerCode
                      : description
              }
              onChange={(e) =>
                field.name === "model_reference"
                  ? setReference(e.target.value)
                  : field.name === "ler_code"
                    ? setLerCode(e.target.value)
                    : setDescription(e.target.value)
              }
            />
          )}
        </Grid>
      );
    }
  });
}
