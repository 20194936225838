import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';
import { WasteTypes } from 'src/models/waste_types';

export interface GetWasteTypeByWoRequest {
  waste_origin_id: number;
  rejection_motive_id: number;
}

export const apiWasteTypes = createApi({
  // reducerPath: 'api_waste_types',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getWasteTypes: builder.mutation<WasteTypes[], void>({
      query: () => ({
        url: 'api/waste-types/getAll',
        method: 'GET',
      }),
    }),
    getWasteOriginByWo: builder.mutation<any, number>({
      query: (id) => ({
        url: `api/waste-origin/get-by-source-type?source_type_id=${id}`,
        method: 'GET',
      }),
    }),
    getWasteTypeByWo: builder.mutation<any, GetWasteTypeByWoRequest>({
      query: (request) => ({
        url: `api/waste-types/get-waste-type-by-wo?waste_origin_id=${request.waste_origin_id}&rejection_motive_id=${request.rejection_motive_id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetWasteTypesMutation,
  useGetWasteOriginByWoMutation,
  useGetWasteTypeByWoMutation,
} = apiWasteTypes;
