import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';
import { CreatedUser } from 'src/models/createdUser';
import { role_type_permission } from 'src/models/role_type_permission';
import { UserInfo } from 'src/models/userInfo';
import { UserList } from 'src/models/user_list';
import { User } from 'src/models/users';

export interface UserResponse {
  message: string;
  user: User;
}

export interface CreateUserRequest {
  username?: string;
  email?: string;
  password?: string;
  phone?: string;
  name?: string;
  surname?: string;
  role_type_permission?: role_type_permission[];
}

export interface CreateUserResponse {
  message: string;
  user: CreatedUser;
}

export interface UserRequest {
  user_id: string;
  userInfo?: UserInfo;
}
export interface UserResponse {
  message: string;
  user: User;
}

export interface DeleteUserResponse {
  message: string;
  user: User;
}

export interface UserOrganizationIdRequest {
  organization_id: string;
}

export const apiUsers = createApi({
  reducerPath: 'api_user',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    create: builder.mutation<CreateUserResponse, CreateUserRequest>({
      query: (CreateUserRequest) => ({
        url: 'api/users/create',
        method: 'POST',
        body: CreateUserRequest,
      }),
    }),
    deleteUser: builder.mutation<DeleteUserResponse, UserRequest>({
      query: (UserRequest) => ({
        url: `api/users/delete?user_id=${UserRequest.user_id}`,
        method: 'DELETE',
      }),
    }),
    getById: builder.mutation<UserResponse, UserRequest>({
      query: (UserRequest) => ({
        url: `api/users/get?user_id=${UserRequest.user_id}`,
        method: 'GET',
      }),
    }),
    getAll: builder.mutation<UserList[], void>({
      query: () => ({
        url: 'api/users/getAll',
        method: 'GET',
      }),
    }),
    getByOrganizationId: builder.mutation<
      UserList[],
      UserOrganizationIdRequest
    >({
      query: (UserOrganizationId) => ({
        url: `api/users/getAllByOrg?organization_id=${UserOrganizationId.organization_id}`,
        method: 'GET',
      }),
    }),
    update: builder.mutation<User, UserRequest>({
      query: (UserRequest) => ({
        url: `api/users/edit?user_id=${UserRequest.user_id}`,
        method: 'PUT',
        body: UserRequest.userInfo,
      }),
    }),
    enableUser: builder.mutation<any, number>({
      query: (userId) => ({
        url: `api/users/enable-user?user_id=${userId}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useCreateMutation,
  useDeleteUserMutation,
  useGetByIdMutation,
  useUpdateMutation,
  useGetByOrganizationIdMutation,
  useGetAllMutation,
  useEnableUserMutation,
} = apiUsers;
