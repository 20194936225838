import { useState, useEffect } from 'react';
import {Snackbar, Alert, AlertColor} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface SnackBarProps {
    open: boolean;
    message: string;
    type: AlertColor;
    setOpen: (open: boolean) => void;
    redirect?: string;
}

function SnackBar(props: SnackBarProps) {
    const { open, message, type, setOpen } = props;
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        setIsOpen(open);
        if(!props.redirect) {
            setTimeout(() => {
                setOpen(false);
            }, 3000);
        }
    }, [open]);
    
    const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') { 
            return;
        }
        setIsOpen(false)
        if (props.redirect) {
            navigate(props.redirect);
        }
    };
    return (
        <Snackbar 
        open={isOpen} 
        onClose={handleToastClose} 
        autoHideDuration={3000} 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert severity={type} sx={{ width: '100%' }}>
                {t(message)}
            </Alert>
        </Snackbar>
    );
}

export default SnackBar;
