import { CompositionDetails } from "src/models/composition-details";

export default function returnCompositionDescription(
  materials: (CompositionDetails & { name?: string })[],
  percentages: number[]
) {

  ////Construir array amb materials percentages //JSM 20240305
  let newMaterialList = [];
  materials.forEach((material, index) => {
    newMaterialList.push({ id: index, idMaterial: materials[index].id, materialName: materials[index].name, materialPercentage: percentages[index] });
  });

  ////Ordenar nou array de materials by percentages DESC //JSM 20240305
  const unspecifiedMaterial = newMaterialList.filter(item => item.idMaterial === 1);
  const otherMaterials = newMaterialList.filter(item => item.idMaterial !== 1);

  otherMaterials.sort((a, b) => b.idMaterial - a.idMaterial);

  const orderedMaterialList = [...otherMaterials, ...unspecifiedMaterial];

  ////Construir el compositionDescription a partir del nou array //JSM 20240305
  let compositionDescription = "";
  if (orderedMaterialList.length == 1) {
    compositionDescription += orderedMaterialList[0].materialName + " " + orderedMaterialList[0].materialPercentage + "%";
  } else {
    orderedMaterialList.forEach((material, index) => {
      ////Si idMaterial != 1 ("No identificado") guarda el material a la descripció, sino no JSM 20240305 //JSM20240314 ha de ser 1
      if (material.idMaterial != 1) {  //JSM20240314 ha de ser 0 ara 12 ara 1
        if (compositionDescription != "") compositionDescription += ", "; //JSM 20240314
        compositionDescription += material.materialName + " " + material.materialPercentage + "%";
        //if (index < materials.length - 1) compositionDescription += ", ";
      } else {
        if (compositionDescription != "") compositionDescription += ", "; //JSM 20240314
        compositionDescription += "NI " + material.materialPercentage + "%";
      }
      //console.log("returnCompositionDescription/compositionDescription: ", compositionDescription); //JSM 20240305
    });
  }

  // let compositionDescription = "";
  // if (materials.length == 1) {
  //   compositionDescription += materials[0].name + " " + percentages[0] + "%";
  // } else {
  //   materials.forEach((material, index) => {
  //     if (material.id != 0) { //JSM20240314 abans 12
  //       compositionDescription +=
  //       (material.name ?? material.description) + " " + percentages[index] + "%";
  //       if (index < materials.length - 1) compositionDescription += ", ";
  //     }
  //   });
  // }


  return compositionDescription;
}