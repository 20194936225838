import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';
import { Organization } from 'src/models/organization';

export interface OrganizationRequest {
  organization_id: string;
}
export interface OrganizationResponse {
  message: string;
  organization: Organization;
}

export interface OrganizationEditRequest {
  org_id: string;
  orgInfo: {
    name?: string;
    cif?: string;
    email?: string;
    phone_number?: string;
    address?: string;
    secondary_address?: string;
    country?: number;
    province?: number;
    town?: number;
    postal_code?: string;
    global_location_number?: string;
    player_types?: number[];
  };
}

export interface EditPlayerTypeRequest {
  addPlayerType: number[];
  org_id: string;
}

export const apiOrganizations = createApi({
  // reducerPath: 'api_organizations',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    createOrganization: builder.mutation<Organization, Organization>({
      query: (Organization) => ({
        url: 'organizations/createOrg',
        method: 'POST',
        body: Organization,
      }),
    }),
    deleteOrganization: builder.mutation<Organization, OrganizationRequest>({
      query: (OrganizationRequest) => ({
        url: `api/organizations/deleteOrg?org_id=${OrganizationRequest.organization_id}`,
        method: 'DELETE',
      }),
    }),
    getOrganizationById: builder.mutation<
      OrganizationResponse,
      OrganizationRequest
    >({
      query: (OrganizationRequest) => ({
        url: `api/organizations/getById?org_id=${OrganizationRequest.organization_id}`,
        method: 'GET',
      }),
    }),
    getAllOrganizations: builder.mutation<Organization[], void>({
      query: () => ({
        url: 'api/organizations/getAllOrg',
        method: 'GET',
      }),
    }),
    editOrganization: builder.mutation<Organization, OrganizationEditRequest>({
      query: (OrganizationEditRequest) => ({
        url: `api/organizations/editOrg?org_id=${OrganizationEditRequest.org_id}`,
        method: 'PUT',
        body: OrganizationEditRequest.orgInfo,
      }),
    }),
    editPlayerType: builder.mutation<Organization, EditPlayerTypeRequest>({
      query: (EditPlayerTypeRequest) => ({
        url: `api/organizations/editPlayerType?org_id=${EditPlayerTypeRequest.org_id}`,
        method: 'PUT',
        body: EditPlayerTypeRequest,
      }),
    }),
    getWamOrgs: builder.mutation<any, void>({
      query: () => ({
        url: 'api/organizations/get-wam-orgs',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetAllOrganizationsMutation,
  useEditOrganizationMutation,
  useGetOrganizationByIdMutation,
  useEditPlayerTypeMutation,
  useGetWamOrgsMutation,
} = apiOrganizations;
