import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';
import { TextilType } from 'src/models/textil_types';

export interface CreateTextilTypeRequest {
  name: string;
  description: string;
}

export interface CreateTextilTypeResponse {
  message: string;
  textil_type: TextilType;
}

export const apiTextilTypes = createApi({
  // reducerPath: 'api_textil_types',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getTextilTypes: builder.mutation<TextilType[], void>({
      query: () => ({
        url: 'api/textil-types/getAll',
        method: 'GET',
      }),
    }),
    createTextilType: builder.mutation<
      CreateTextilTypeResponse,
      CreateTextilTypeRequest
    >({
      query: (body) => ({
        url: 'api/textil-types/create',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetTextilTypesMutation, useCreateTextilTypeMutation } =
  apiTextilTypes;
