import Card from "@mui/material/Card";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useCreateOrderHeaderMutation,
  useCreateOrderLinesMutation,
} from "src/api/orders";
import { useCreateShipmentMutation } from "src/api/shipments";
import { useGetWasteOriginByWoMutation } from "src/api/wasteTypes";
import SnackBar from "src/components/SnackBar";
import { useAppSelector } from "src/hooks/store";
import returnCompositionDescription from "src/utils/returnCompositionDescription";
import CreateOrderHeader from "./CreateOrderHeaderForm";
import CreateOrderLines from "./CreateOrderLinesForm";
import {
  IPerformReceptionRequest,
  usePerformReceptionMutation,
} from "src/api/products";
import { ProductStatusEnum } from "src/models/product_status";
import { ProcessModal } from "./Modal";

const ReceptionNoShipment: React.FC = () => {
  // JSM 20240423 Recuperem dades del user
  const authInfo = useAppSelector((state) => {
    //console.log(state); // Log the state for debugging
    return state.auth.user; // Return the user object from the auth slice
  });
  const roles = authInfo?.role_type_permission.map((role) => role.role);
  const organization_id = authInfo?.organization_id;
  const player_type = authInfo?.logistic_center_id?.player_type.type; //JSM 20240422

  const orgId = useAppSelector((state) => state?.auth?.user?.organization_id);
  const orderHeaderId = useAppSelector(
    (state) => state?.orders?.orderHeader?.id
  );
  const shipmentId = useAppSelector((state) => state?.shipments?.shipment?.id);
  const [activeStep, setActiveStep] = useState(0);
  const [activeLineIndex, setActiveLineIndex] = useState(0);
  const [orderData, setOrderData] = useState({
    header: {
      order_number: null,
      request_date: null,
      origin: null,
      brand: null,
      waste_management_company: null,
      delivery_condition: null,
      load_date: null,
      validated: null,
      owner_id: null,
      shipment: null,
      logistic_center: null,
      source_type: null,
      waste_management_facility: null,
    },
    lines: [],
    shipment: {
      origin: null,
      destination: null,
      shipping_date: null,
      reception_date: null,
      shipping_company_id: 3,
      carrier_id: 1,
      responsible_id: 1,
      status_id: 1,
      sender_id: 1,
      receiver_id: 1,
    },
  });
  const { t } = useTranslation();
  const [steps, setSteps] = useState([
    { label: t("deliveryInformation") },
    { label: `${t("lot")} ${orderData?.lines?.length + 1}` },
  ]);
  const [
    createOrderHeader,
    {
      isLoading: isLoadingOrderHeader,
      isSuccess: isSuccessOrderHeader,
      isError: isErrorOrderHeader,
    },
  ] = useCreateOrderHeaderMutation();
  const [
    createOrderLines,
    {
      isLoading: isLoadingOrderLine,
      isSuccess: isSuccessOrderLine,
      isError: isErrorOrderLine,
    },
  ] = useCreateOrderLinesMutation();
  const [
    createShipment,
    {
      isLoading: isLoadingOrderShipment,
      isSuccess: isSuccessOrderShipment,
      isError: isErrorOrderShipment,
    },
  ] = useCreateShipmentMutation();

  const [
    performReception,
    {
      isLoading: isPerformingReception,
      isSuccess: wasReceptionPerformedSuccessfully,
      isError: wasReceptionPerformedWithError,
    },
  ] = usePerformReceptionMutation();

  const [
    getWasteOriginByWo,
    { data: wasteOriginData, isLoading: wasteOriginLoading },
  ] = useGetWasteOriginByWoMutation();
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [selectedOrigin, setSelectedOrigin] = useState(null);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [resultShipment, setResultShipment] = useState(null);
  const [resultLine, setResultLine] = useState([]);
  const [openModal, setOpenModal] = useState(false); //JSM 20240419

  const handleAddLotStep = () => {
    setSteps((prevSteps) => [
      ...prevSteps,
      { label: `${t("lot")} ${activeLineIndex + 2}` },
    ]);
  };

  const handleNext = () => {
    if (activeStep === 0) {
      setActiveStep(1);
      setActiveLineIndex(0);
    } else {
      setActiveLineIndex((prevIndex) => prevIndex + 1);
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 1 && activeLineIndex === 0) {
      setActiveStep(0);
      setActiveLineIndex(-1);
    } else {
      setActiveLineIndex((prevIndex) => prevIndex - 1);
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const handleCreateOrder = async () => {
    const shipment = orderData.shipment;
    const resultShipment = await createShipment({
      origin: shipment.origin,
      destination: shipment.destination,
      shipping_date: shipment.shipping_date,
      reception_date: shipment.reception_date,
      shipping_company_id: shipment.shipping_company_id,
      carrier_id: shipment.carrier_id,
      responsible_id: shipment.responsible_id,
      status_id: /*shipment.status_id*/ 5, //MNS aixo ha de ser un enum
      sender_id: shipment.sender_id,
      receiver_id: shipment.receiver_id,
    });
    setResultShipment(resultShipment);
    //setOpenModal(true); //JSM 20240419 Descomentar x fer q pregunti
  };

  const returnCompositionDetails = (materials, percentages) => {
    const details = [];
    materials.forEach((material, position) => {
      details.push({
        conventional: Number(percentages[position]),
        pre_consumer_recycled: null,
        post_consumer_recycled: null,
        organic: null,
        textil_type_id: material.id, //JSM 20240306 això està malament!!! TODO ha de tornar composition_details.textile_type_id
        description: material.name,
      });
    });
    return details;
  };

  useEffect(() => {
    if (orderData?.header?.source_type)
      getWasteOriginByWo(orderData?.header?.source_type);
  }, [orderData?.header?.source_type]);

  let lineResults = [];
  const createOrderLinesAsync = async () => {
    lineResults = [];
    if (isSuccessOrderHeader && orderHeaderId) {
      //MNS aqui abans hi habia un .map() que donaba problemes perque Since createOrderLines is asynchronous, all the promises are created concurrently i amb un for ... of ... ja no tenim aquest problema
      for (const line of orderData.lines) {
        try {
          let materialsCopy = [];
          let percentagesCopy = [];
          if (line.specifyComposition === true) {
            materialsCopy = [...line.materials];
            percentagesCopy = [...line.percentages];
            if (percentagesCopy[0] === 0) {
              materialsCopy.shift();
              percentagesCopy.shift();
            }
          }
          const resultLine = await createOrderLines({
            order_header_id: orderHeaderId,
            model_reference: line.model_reference,
            quantity: line.quantity,
            weight: line.weight,
            num_package: line.num_package,
            pallets: line.pallets,
            closed_circulation: line.closed_circulation,
            description: line.description || "",
            attachements: line.attachements,
            color_id: line.color_id || null,
            series: line.series,
            waste_type: line.waste_type,
            composition:
              line.specifyComposition === false
                ? {
                    description: returnCompositionDescription(
                      line.materials,
                      line.percentages
                    ),
                    composition_details: returnCompositionDetails(
                      line.materials,
                      line.percentages
                    ),
                  }
                : {
                    description: returnCompositionDescription(
                      materialsCopy,
                      percentagesCopy
                    ),
                    composition_details: returnCompositionDetails(
                      materialsCopy,
                      percentagesCopy
                    ),
                  },
            waste_origin: line.waste_origin,
            rejection_motive:
              line.waste_origin == 1 ? line.rejection_motive : null,
            ler_code: line.ler_code,
            origin: line.origin,
          });
          lineResults.push(resultLine);
        } catch (error) {
          console.error("Error creating order line:", error);
        }
      }
    }
    return lineResults;
  };

  useEffect(() => {
    const fetchResultLines = async () => {
      try {
        const resultLines = await createOrderLinesAsync();
        setResultLine(resultLines);
      } catch (error) {
        console.error("Error fetching result lines:", error);
      }
    };

    fetchResultLines();
  }, [isSuccessOrderHeader, orderHeaderId, orderData.lines]);

  useEffect(() => {
    if (isSuccessOrderShipment && shipmentId && !isSuccessOrderHeader) {
      const orderHeader = orderData.header;
      createOrderHeader({
        order_number: orderHeader.order_number,
        request_date: orderHeader.request_date,
        /* origin: orderHeader.origin, */
        waste_management_company: orderHeader.waste_management_company,
        delivery_condition: orderHeader.delivery_condition,
        validated: orderHeader.validated,
        owner_id: orderHeader.owner_id,
        shipment: shipmentId,
        logistic_center: orderHeader.logistic_center,
        source_type: orderHeader.source_type,
        waste_management_facility: orderHeader.waste_management_facility,
      });
    }
  }, [isSuccessOrderShipment]);

  useEffect(() => {
    if (isSuccessOrderLine) setIsOpenSuccess(true);
    if (
      isErrorOrderLine ||
      isErrorOrderHeader ||
      isErrorOrderShipment ||
      wasReceptionPerformedWithError
    )
      setIsOpenError(true);
  }, [
    isSuccessOrderLine,
    isErrorOrderLine,
    isErrorOrderHeader,
    isErrorOrderShipment,
    wasReceptionPerformedWithError,
  ]);

  useEffect(() => {
    const performReceptionAsync = async () => {
      if (
        isSuccessOrderHeader &&
        orderHeaderId &&
        isSuccessOrderLine &&
        isSuccessOrderShipment &&
        shipmentId &&
        resultLine.length != 0
      ) {
        for (let index = 0; index < orderData.lines.length; index++) {
          const line = orderData.lines[index];
          const resultLineData = resultLine[index].data.order_line; //resultLineData te les linies en ordre incorrecte

          const receptionData: IPerformReceptionRequest = {
            composition: {
              id: resultLineData.composition_id.id || 1, //MNS he afegit l'id
              description: line.composition_id.description || "",
              organizationID: orgId,
              details:
                line.materials && line.percentages
                  ? line.materials.map((material, index) => ({
                      description: material.name,
                      conventional: line.percentages[index],
                      textileTypeID: material.id,
                    }))
                  : [
                      {
                        description: "No especificado",
                        conventional: 0,
                        textileTypeID: 1,
                      },
                    ],
            },
            product: {
              orderHeaderID: orderHeaderId,
              isSpecifyComposition: Boolean(line.composition_id.description),
              creation_date: new Date().toISOString(),
              percentage: 0,
              discarded: 0,
              quantity: line.quantity,
              weight: line.weight,
              pallets: line.pallets,
              unit_weight: 0,
              reclassification: 0,
              new_color_id: line.color_id || null,
              a3: 0,
              comments: line.description || null,
              product_type: isNaN(line.product_type)
                ? line.specifyComposition != false
                  ? 3
                  : 2
                : line.product_type,
              responsible: orderData.header.owner_id,
              shipment: resultShipment.data.shipment.id,
              status:
                line.series === 1
                  ? ProductStatusEnum.CLASSIFIED
                  : ProductStatusEnum.PENDING_CLASSIFICATION,
              order_line_id: resultLineData.id,
              locationICont: line.locationICont,
              centerICont: authInfo?.role_type_permission.some(
                (permission) => permission.role === 1
              )
                ? 3
                : authInfo?.logistic_center_id.id,
            },
            containers: line.container || [],
          };
          await performReception(receptionData);
        }
      }
    };

    performReceptionAsync();
  }, [
    isSuccessOrderHeader,
    orderHeaderId,
    isSuccessOrderLine,
    isSuccessOrderShipment,
    shipmentId,
    orderData,
    resultLine,
  ]);

  return (
    <>
      <Card sx={{ p: 5, m: 4, borderRadius: 2, minHeight: "75vh" }}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            backgroundColor: "white",
            marginBottom: 2,
            maxWidth: "100%",
            overflowX: "scroll",
          }}
        >
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === 0 ? (
            <>
              <CreateOrderHeader
                orderData={orderData.header}
                shipmentData={orderData.shipment}
                setOrderData={setOrderData}
                activeStep={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                authInfo={authInfo} //JSM 20240423
                originComponent="ReceptionNoShipment" //JSM 20240426
              />
              {/* //JSM 20240419 */}
              {/* {console.log("activeStep (CreateOrderHeader): ", activeStep)}
              {console.log("activeLineIndex (CreateOrderHeader): ", activeLineIndex)} */}
            </>
          ) : (
            <>
              <CreateOrderLines
                orderData={orderData}
                setOrderData={setOrderData}
                activeLineIndex={activeLineIndex}
                setActiveLineIndex={setActiveLineIndex}
                handleBack={handleBack}
                handleNext={handleNext}
                handleAddLotStep={handleAddLotStep}
                handleCreateOrder={handleCreateOrder}
                wasteOriginData={wasteOriginData}
                authInfo={authInfo} //JSM 20240423
                originComponent="ReceptionNoShipment" //JSM 20240426
              />
              {/* //JSM 20240419 */}
              {/* {setOpenModal(true)} */}
            </>
          )}
        </div>
        <SnackBar
          open={isOpenSuccess}
          setOpen={setIsOpenSuccess}
          redirect={"/management/lots/all"}
          type="success"
          message={t("receptionPerformedCorrectly")}
        />
        <SnackBar
          open={isOpenError}
          setOpen={setIsOpenError}
          type="error"
          message={t("deliveryCreatedError")}
        />
      </Card>
      {/* //JSM 20240419 */}
      <ProcessModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        question={"endProcessMessage2"}
      />
      &nbsp;
    </>
  );
};

export default ReceptionNoShipment;
