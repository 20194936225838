import { LoadingButton } from "@mui/lab";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetColorsMutation } from "src/api/colors";
import { useGetCompositionsMutation } from "src/api/compositions";
import { useGetFacilityTypesMutation } from "src/api/facilityTypes";
import { useGetLogisticCentersMutation } from "src/api/logisticCenters";
import { useGetRejectionMotiveMutation } from "src/api/rejectionMotive";
import { useGetWasteTypeByWoMutation } from "src/api/wasteTypes";
import Composition from "src/components/CompositionForm/Composition";
import { useAppSelector } from "src/hooks/store";
import { returnLerCode } from "src/utils/returnLerCode";
import EndProcessModal from "./Modal";
import renderInputs from "./renderOrderLinesInputs";
import { IContainer, IContainerDataGridRow } from "src/models/container";
import { useGetAllContainersInfoMutation } from "src/api/containers";
//import { useGetAllContainerZonesMutation } from "src/api/containerZones";
import { ContainerZoneDTO } from "src/models/containerZone.dto";
import { useGetAllLocationsMutation } from "src/api/locations";
import UbicationModal from "src/components/Modal/UbicationModal";
import { useGetAllOrganizationsMutation } from "src/api/organizations";
import { useGetAllProductTypesMutation } from "src/api/products";

interface CreateOrderLinesProps {
  orderData: any;
  setOrderData: any;
  activeLineIndex: number;
  setActiveLineIndex: any;
  wasteOriginData: any;
  handleBack: () => void;
  handleNext: () => void;
  handleAddLotStep: () => void;
  handleCreateOrder: () => void;
  authInfo: any; //JSM 20240423
  originComponent: any; //JSM 20240424
}

const CreateOrderLinesForm: React.FC<CreateOrderLinesProps> = ({
  orderData,
  setOrderData,
  activeLineIndex,
  setActiveLineIndex,
  handleBack,
  handleNext,
  handleAddLotStep,
  handleCreateOrder,
  wasteOriginData,
  authInfo, //JSM 20240423
  originComponent, //JSM 20240424
}) => {
  //console.log("order data", orderData);
  //const roles = authInfo?.role_type_permission.map((role) => role.role);
  const organization_id = authInfo?.organization_id; //JSM 20240423
  const player_type = authInfo?.logistic_center_id?.player_type.type; //JSM 20240422
  const userEmail = authInfo?.email; //JSM 20240425

  const { t } = useTranslation();

  const [getColors, { data: colorsData, isLoading: colorsLoading }] =
    useGetColorsMutation();
  const [
    getCompositions,
    { data: compositionsData, isLoading: compositionsLoading },
  ] = useGetCompositionsMutation();
  const [
    getRejectionMotive,
    { data: rejectionMotiveData, isLoading: rejectionMotiveLoading },
  ] = useGetRejectionMotiveMutation();
  const [getProductType, { data: productTypeData }] =
    useGetAllProductTypesMutation();
  const [
    getWasteTypes,
    { data: wasteTypesData, isLoading: wasteTypesLoading },
  ] = useGetWasteTypeByWoMutation();
  const [
    getFacilityTypes,
    { data: facilityTypes, isLoading: isLoadingFacilityTypes },
  ] = useGetFacilityTypesMutation();
  const [
    getLogisticCenters,
    { data: logisticCenters, isLoading: isLoadingLogisticCenters },
  ] = useGetLogisticCentersMutation();
  const [
    getAllOrganizations,
    { data: organizations, isLoading: organizationsLoading },
  ] = useGetAllOrganizationsMutation();
  const [
    getContainers,
    { data: containerData, isSuccess: isSuccessContainers },
  ] = useGetAllContainersInfoMutation();
  const [getAllContainerZones, { data: containerZones }] =
    useGetAllLocationsMutation();

  const orderHeader = useAppSelector((state) => state.orders.orderHeader);

  const [reference, setReference] = useState(
    orderData?.lines[activeLineIndex]?.model_reference || ""
  );
  const [description, setDescription] = useState(
    orderData?.lines[activeLineIndex]?.description || ""
  );

  const [quantity, setQuantity] = useState(
    orderData?.lines[activeLineIndex]?.quantity || ""
  );
  const [weight, setWeight] = useState(
    orderData?.lines[activeLineIndex]?.weight || ""
  );
  const [pallets, setPallets] = useState(
    orderData?.lines[activeLineIndex]?.pallets || 0
  ); //JSM 20240508
  const [numPackage, setNumPackage] = useState(
    orderData?.lines[activeLineIndex]?.num_package || ""
  ); //JSM 20240508

  const [closedCirculation, setClosedCirculation] = useState(
    orderData?.lines[activeLineIndex]?.closed_circulation
  );

  const [wasteType, setWasteType] = useState(
    orderData?.lines[activeLineIndex]?.waste_type || ""
  );
  const [rejectionMotive, setRejectionMotive] = useState(
    orderData?.header?.rejection_motive || ""
  );
  const [lerCode, setLerCode] = useState(
    orderData?.lines[activeLineIndex]?.ler_code || ""
  );
  const [wasteOrigin, setWasteOrigin] = useState(
    orderData?.lines[activeLineIndex]?.waste_origin || ""
  );

  const [series, setSeries] = useState(
    orderData?.lines[activeLineIndex]?.series
  );
  const [specifyComposition, setSpecifyComposition] = useState(false);
  const [color, setColor] = useState(
    orderData?.lines[activeLineIndex]?.color_id || ""
  );
  const [composition, setComposition] = useState(
    orderData?.lines[activeLineIndex]?.composition_id || ""
  );
  const [materials, setMaterials] = useState(
    orderData?.lines[activeLineIndex]?.materials || []
  );
  const [percentages, setPercentages] = useState(
    orderData?.lines[activeLineIndex]?.percentages || []
  );

  const [origin, setOrigin] = useState(
    orderData?.lines[activeLineIndex]?.origin ||
      orderData?.header?.logistic_center
  );
  const [destination, setDestination] = useState(
    orderData?.header?.waste_management_facility || ""
  );

  const [containers, setContainers] = useState<IContainer[]>([]);
  const [selectedContainers, setSelectedContainers] = useState<
    IContainerDataGridRow[]
  >([]);
  const [locationICont, setLocationICont] = useState(null);
  const [centerICont, setCenterICont] = useState(null);

  const [openModal, setOpenModal] = useState(false);
  const [openModalUbications, setOpenModalUbications] = useState(false);
  const isLoadingData =
    colorsLoading || compositionsLoading || rejectionMotiveLoading;

  useEffect(() => {
    getLogisticCenters();
    getFacilityTypes();
    getContainers(); //MNS
    getAllContainerZones(); //MNS
    getAllOrganizations();
  }, []);

  useEffect(() => {
    if (isSuccessContainers) {
      setContainers(containerData);
    }
    //console.log("CD1", containers);
  }, [isSuccessContainers]);
  useEffect(() => {
    //console.log("CD2", containers);
  }, [containers]);

  useEffect(() => {
    if (!orderData?.header) return;
    getColors();
    getCompositions();
    getRejectionMotive();
    getProductType();
  }, [orderData?.header]);

  useEffect(() => {
    getWasteTypes({
      waste_origin_id: wasteOrigin,
      rejection_motive_id: rejectionMotive,
    });
  }, [wasteOrigin, rejectionMotive]);

  useEffect(() => {
    setLerCode(returnLerCode(wasteOrigin, rejectionMotive));
  }, [wasteOrigin, rejectionMotive]);

  useEffect(() => {
    if (!wasteTypesData) return;
    setWasteType(wasteTypesData?.id);
  }, [wasteTypesData]);

  useEffect(() => {
    // setReference(orderData?.lines[activeLineIndex]?.model_reference || "");
    setReference(
      orderData?.header.order_number + "-" + (activeLineIndex + 1) || ""
    ); //JSM 20240521
    setDescription(orderData?.lines[activeLineIndex]?.description || "");

    setQuantity(orderData?.lines[activeLineIndex]?.quantity || "");
    setWeight(orderData?.lines[activeLineIndex]?.weight || "");
    setPallets(orderData?.lines[activeLineIndex]?.pallets || 0); //JSM 20240508
    setNumPackage(orderData?.lines[activeLineIndex]?.num_package || ""); //JSM 20240508

    setClosedCirculation(orderData?.lines[activeLineIndex]?.closed_circulation);

    setWasteType(orderData?.lines[activeLineIndex]?.waste_type || "");

    setColor(orderData?.lines[activeLineIndex]?.color_id || "");
    setComposition(orderData?.lines[activeLineIndex]?.composition_id || "");
    setSeries(orderData?.lines[activeLineIndex]?.series);
    setMaterials(
      orderData?.lines[activeLineIndex]?.materials || [
        {
          id: 1,
          name: "No especificado",
          description: null,
        },
      ]
    );
    setPercentages(orderData?.lines[activeLineIndex]?.percentages || [100]);

    // Calculate the sum of percentages
    const sumOfPercentages = percentages.reduce((acc, curr) => acc + curr, 0);

    if (sumOfPercentages === 100) {
    } else {
      // Check if there is a material with ID 12
      const materialWithID1 = materials.find((material) => material.id === 1); // MNS ara te un 1

      if (!materialWithID1) {
        // Adjust percentages and add 'No especificado id 12'
        const difference = 100 - sumOfPercentages;
        percentages.push(difference);
        materials.push({
          id: 1, // MNS ara te un 1
          name: "No especificado",
          description: null,
        });
      }
    }

    setSpecifyComposition(
      orderData?.lines[activeLineIndex]?.specifyComposition || false
    );
    setWasteOrigin(orderData?.lines[activeLineIndex]?.waste_origin || "");
    setRejectionMotive(
      orderData?.lines[activeLineIndex]?.rejection_motive || ""
    );
    setLerCode(orderData?.lines[activeLineIndex]?.ler_code || "");
    setOrigin(
      orderData?.lines[activeLineIndex]?.origin ||
        orderData?.header?.logistic_center
    );
    setDestination(orderData?.header?.waste_management_facility || "");

    setSelectedContainers(orderData?.lines[activeLineIndex]?.container || []);

    //console.log("Materials", materials);
    //console.log("Percentatge", percentages);
  }, [activeLineIndex]); //aquet useEffect es fa quan pulsas el boto guardar

  useEffect(() => {
    if (series === 0 || wasteOrigin === 3) {
      setColor("");
      setMaterials([]);
      setPercentages([]);
      setSpecifyComposition(false);
    }
    if (wasteOrigin === 2 || wasteOrigin === 3) {
      setSeries(0);
    }
  }, [series, wasteOrigin]);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    console.log(event.currentTarget);
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const finalData = {
      order_header_id: orderHeader?.id,
      model_reference: formData.get("model_reference") as string,
      quantity: parseInt(formData.get("quantity") as string) || null,
      weight: parseFloat(formData.get("weight") as string),
      pallets: parseInt(formData.get("pallets") as string) || pallets, //JSM 20240508
      num_package: parseInt(formData.get("num_package") as string), //JSM 20240508
      // num_package: null,
      color_id: parseInt(formData.get("color_id") as string),
      composition_id: parseInt(formData.get("composition_id") as string),
      series: parseInt(formData.get("series") as string),
      // company_type: formData.get("company_type") as string,
      closed_circulation: parseInt(
        formData.get("closed_circulation") as string
      ),
      description: formData.get("description") as string,
      attachements: null,
      waste_type: parseInt(wasteType),
      materials: materials,
      percentages: percentages,
      specifyComposition: specifyComposition,
      waste_origin: parseInt(formData.get("waste_origin") as string),
      rejection_motive: parseInt(formData.get("rejection_motive") as string),
      product_type: parseInt(formData.get("product_type") as string), //MNS
      ler_code: formData.get("ler_code") as string,
      origin: parseInt(origin),
      container: selectedContainers,
      locationICont: locationICont,
      centerICont: centerICont,
    };

    if (activeLineIndex === orderData.lines.length) {
      setOrderData((prevData: any) => {
        return {
          ...prevData,
          lines: [...prevData.lines, finalData],
        };
      });
      handleNext();
      handleAddLotStep();
    } else {
      setOrderData({
        ...orderData,
        lines: orderData?.lines?.map((item, index) => {
          if (index === activeLineIndex) {
            return finalData;
          }
          return item;
        }),
      });
      handleNext();
    }
  }

  const getDefaultWasteManageId = () => {
    const selectedWasteManage = facilityTypes?.find(
      (facilityType) =>
        facilityType.id === orderData?.header?.waste_management_facility
    );
    return selectedWasteManage ? selectedWasteManage.id : "";
  };

  const formFields = [
    {
      name: "model_reference",
      label: t("reference"),
      type: "string",
      xs: 12,
      sm: 6,
      defaultValue: activeLineIndex,
    },
    {
      name: "description",
      label: t("description"),
      type: "string",
      xs: 12,
      sm: 12,
      required: false,
      fullWidth: true,
    },

    {
      name: "pallets",
      label: t("pallets"),
      type: "number",
      xs: 12,
      sm: 6,
      required: false,
    },
    {
      name: "num_package",
      label: t("numPackage"),
      type: "number",
      xs: 12,
      sm: 6,
      required: false,
    },
    {
      name: "quantity",
      label: t("quantity"),
      type: "number",
      xs: 12,
      sm: 6,
      required: false,
    },
    {
      name: "weight",
      label: `${t("totalLotWeight")}(Kg)`,
      type: "number",
      xs: 12,
      sm: 6,
      visible: true,
    },

    {
      name: "waste_origin",
      label: t("wasteOrigin"),
      type: "select",
      xs: 12,
      sm: 6,
      items: wasteOriginData?.map((item) => ({
        value: item.id,
        label: t(item.name),
      })),
    },
    {
      name: "rejection_motive",
      label: t("rejectionMotive"),
      type: "select",
      xs: 12,
      sm: 6,
      items: rejectionMotiveData?.map((item) => ({
        value: item.id,
        label: t(item.motive),
        visible: userEmail == "integer.admin@integer.com",
      })),
    },
    {
      name: "product_type", //MNS
      label: t("productType"),
      type: "select",
      xs: 12,
      sm: 6,
      items: productTypeData?.map((item) => ({
        value: item.id,
        label: t(item.type),
        //visible: userEmail == "integer.admin@integer.com",
      })),
    },
    {
      name: "ler_code",
      label: t("lerCode"), //JSM 20240509 TODO afegir desc al code, t(item.description)
      type: "string",
      xs: 12,
      sm: 6,
      visible: userEmail == "integer.admin@integer.com",
      required: false,
    },

    {
      name: "closed_circulation",
      label: t("closedCirculation"),
      type: "select",
      xs: 12,
      sm: 6,
      items: [
        { value: 0, label: t("No") },
        { value: 1, label: t("Yes") },
      ],
    },

    {
      name: "series",
      label: t("series"),
      type: "select",
      xs: 12,
      sm: 6,
      items:
        wasteOrigin == 2
          ? [{ value: 0, label: t("No") }]
          : [
              { value: 0, label: t("No") },
              { value: 1, label: t("Yes") },
            ],
    },
    {
      name: "color_id",
      label: t("color"),
      type: "select",
      xs: 12,
      sm: 6,
      items: colorsData?.map((item) => ({
        value: item.id,
        label: t(item.color), //+ " " + item.color_code, JSM 20240509
        color: item.color_code,
      })),
      //visible: true,
      visible:
        ["WAP"].includes(player_type) ||
        userEmail == "integer.admin@integer.com", //JSM 20240509
    },

    {
      type: "title",
      label: t("containersSelection"),
      isVisible: true,
    },
    {
      name: "container",
      label: t("container"),
      type: "select",
      isVisible: true,
      xs: 12,

      items: containers,
    },

    /*{
          name: "waste_type",
          label: t("wasteType"),
          type: "string",
          xs: 12,
          sm: 6,
        },*/

    // JSM 20240417 Ocultem els camps origen i destino xq no aporten res i ja s'han omplert en el header
    // {
    //   name: "logistic_center_id",
    //   label: t("logisticCenter"),
    //   type: "select",
    //   xs: 12,
    //   sm: 6,
    //   items: logisticCenters?.map((logisticCenter) => ({
    //     value: logisticCenter.id,
    //     label: logisticCenter.name + " - " + logisticCenter.organization.name,
    //   })),
    //   defaultValue: origin,
    // },
    // {
    //   name: "waste_management_facility",
    //   label: t("wasteManagementFacility"),
    //   type: "select",
    //   xs: 12,
    //   sm: 6,
    //   items: facilityTypes
    //     ?.filter(
    //       (facilityType) =>
    //         facilityType.organization.id ===
    //         orderData?.header?.waste_management_company
    //     )
    //     ?.map((facilityType) => ({
    //       value: facilityType.id,
    //       label: t(facilityType.name) + " - " + facilityType.organization.name,
    //     })),
    //   defaultValue: getDefaultWasteManageId(),
    // },
    // {
    //   name: "composition_id",
    //   label: t("composition"),
    //   type: "select",
    //   xs: 12,
    //   sm: 6,
    //   items: compositionsData?.map((item) => ({
    //     value: item.id,
    //     label: item.description,
    //   })),
    // },
    // {
    //   name: "company_type",
    //   label: t("companyType"),
    //   type: "string",
    //   xs: 12,
    //   sm: 6,
    // },

    // {
    //   name: "attachements",
    //   label: t("attachements"),
    //   type: "file",
    //   xs: 12,
    //   sm: 12,
    //   required: false,
    // },
  ];
  /*const [onClickEvent, setOnClickEvent] = useState(null);

  function handleFinish(selectedCenter: string, selectedLocation: string) {
    //arriben les dades correctes
    setLocationICont(selectedLocation);
    setCenterICont(selectedCenter);
    if (onClickEvent) {
      saveForm(onClickEvent);
    }
  }*/
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "25px",
        }}
      ></Box>
      {isLoadingData ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            sx={{ marginTop: 5 }}
            size={64}
            disableShrink
            thickness={3}
          />
        </Box>
      ) : (
        <form onSubmit={handleSubmit}>
          <Typography variant="h3" gutterBottom mb={3}>
            {t("lotInfo")}
          </Typography>
          {containers.length > 0 && (
            <Grid container spacing={3} xs={12} md={12}>
              {renderInputs(
                formFields,
                t,
                setColor,
                color,
                setComposition,
                composition,
                setClosedCirculation,
                closedCirculation,
                wasteType,
                setWasteType,
                series,
                setSeries,
                reference,
                setReference,
                quantity,
                setQuantity,
                weight,
                setWeight,
                pallets,
                setPallets, //JSM 20240508
                numPackage,
                setNumPackage,
                description,
                setDescription,
                wasteOrigin,
                setWasteOrigin,
                rejectionMotive,
                setRejectionMotive,
                lerCode,
                setLerCode,
                origin,
                setOrigin,
                setDestination,
                selectedContainers,
                setSelectedContainers,
                player_type,
                userEmail
              )}
            </Grid>
          )}
          {/* {series === 1 && wasteOrigin !== 3 && ( */}
          {wasteOrigin !== 2 && ( //JSM 20240422 wasteOrigin: 2 (Urban Collection Container). Ara mostra compo sempre, excepte si és material de contenidor (SmartConts)
            <>
              <Typography variant="h4" gutterBottom mb={0} mt={3}>
                {t("composition")}
              </Typography>
              <Composition
                series={series}
                materials={materials}
                setMaterials={setMaterials}
                percentages={percentages}
                setPercentages={setPercentages}
                specifyComposition={specifyComposition}
                setSpecifyComposition={setSpecifyComposition}
              />
            </>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 5,
            }}
          >
            <Box sx={{ display: "flex", marginTop: 5 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleBack()}
                sx={{ height: 45 }}
              >
                <span>{t("back")}</span>
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                marginTop: 5,
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              {activeLineIndex === orderData?.lines?.length ? (
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    marginLeft: { xs: 1, sm: 2 },
                    height: 45,
                    marginBottom: { xs: 1, sm: 0 },
                  }}
                >
                  <span>{t("save")}</span>
                </LoadingButton>
              ) : (
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    marginLeft: { xs: 1, sm: 2 },
                    height: 45,
                    marginBottom: { xs: 1, sm: 0 },
                  }}
                >
                  <span>{t("next")}</span>
                </LoadingButton>
              )}
              {orderData?.lines?.length > 0 && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpenModal(true)}
                  sx={{ marginLeft: { xs: 1, sm: 2 }, height: 45 }}
                >
                  <span>{t("finish")}</span>
                </Button>
              )}
            </Box>
          </Box>
          {/*<UbicationModal
            open={openModalUbications}
            onClose={() => {
              setOpenModalUbications(false);
            }}
            question="?"
            button1="no"
            button2="si"
            handleFinish={handleFinish}
            orgList={organizations}
            centerList={logisticCenters}
            locationsList={containerZones}
          />*/}
          <EndProcessModal
            open={openModal}
            onClose={() => setOpenModal(false)}
            handleCreateOrder={handleCreateOrder}
          />
        </form>
      )}
    </>
  );
};

export default CreateOrderLinesForm;
