import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';
import { Receptions } from 'src/models/receptions';
import { Shippments } from 'src/models/shipments';

export interface ReceptionsResponse {
  message: string;
  receptions: Receptions[];
}

export interface PendingReceptionsResponse {
  message: string;
  pendingReceptions: Receptions[];
}

export interface UpdateShipmentStatusRequest {
  shipment_id: number;
  shipment_status_id: number;
}

export interface UpdateShipmentStatusResponse {
  message: string;
  shipment: Receptions;
}

export interface CreateShipmentRequest {
  origin: string;
  destination: string;
  shipping_date: string;
  reception_date: string;
  shipping_company_id: number;
  carrier_id: number;
  responsible_id: number;
  status_id: number;
  sender_id: number;
  receiver_id: number;
}

export interface CreateShipmentResponse {
  message: string;
  shipment: Shippments;
}

export const apiShipments = createApi({
  reducerPath: 'api_shipments',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    createShipment: builder.mutation<CreateShipmentResponse, any>({
      query: (body) => ({
        url: `api/shipments/create`,
        method: 'POST',
        body,
      }),
    }),
    getShipments: builder.mutation<Shippments[], void>({
      query: () => ({
        url: 'api/shipments/getAll',
        method: 'GET',
      }),
    }),
    getReceptions: builder.mutation<ReceptionsResponse, void>({
      query: () => ({
        url: 'api/shipments/receptions',
        method: 'GET',
      }),
    }),
    getPendingReceptions: builder.mutation<PendingReceptionsResponse, void>({
      query: () => ({
        url: 'api/shipments/pending-receptions',
        method: 'GET',
      }),
    }),
    updateShipmentStatus: builder.mutation<
      UpdateShipmentStatusResponse,
      UpdateShipmentStatusRequest
    >({
      query: (body) => ({
        url: 'api/shipments/updateShipmentStatus',
        method: 'PUT',
        body,
      }),
    }),
    getAllShipments: builder.mutation<any, void>({
      query: () => ({
        url: 'api/shipments/all-receptions',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetShipmentsMutation,
  useGetReceptionsMutation,
  useGetPendingReceptionsMutation,
  useUpdateShipmentStatusMutation,
  useCreateShipmentMutation,
  useGetAllShipmentsMutation,
} = apiShipments;
