import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';

export const apiRejectionMotive = createApi({
  // reducerPath: 'api_rejection_motive',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getRejectionMotive: builder.mutation<any, void>({
      query: () => ({
        url: 'api/rejection-motives/getAll',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetRejectionMotiveMutation } = apiRejectionMotive;
