import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';
import { SourceTypes } from 'src/models/source_types';

export const apiSourceTypes = createApi({
  // reducerPath: 'api_source_types',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getSourceTypes: builder.mutation<SourceTypes[], void>({
      query: () => ({
        url: 'api/source-types/getAll',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetSourceTypesMutation } = apiSourceTypes;
