import { configureStore } from "@reduxjs/toolkit";
import { adminApi } from "src/api/admin";
import { apiAuth } from "src/api/auth";
import { apiColors } from "src/api/colors";
import { apiCompositions } from "src/api/compositions";
import { apiOrders } from "src/api/orders";
import { apiShipments } from "src/api/shipments";
import { apiUsers } from "src/api/users";
import { wasteOrdersApi } from "src/api/waste-orders";
import authReducer from "./auth/authSlice";
import compositionsReducer from "./compositions/compositionsSlice";
import ordersReducer from "./orders/ordersSlice";
import shipmentsReducer from "./shipments/shipmentsSlice";
import userReducer from "./users/userSlice";
import { apiContainers } from "src/api/containers";
import { operationsApi } from "src/api/operations";

const persistanceMiddleware = (store: any) => (next: any) => (action: any) => {
  next(action);

  if (
    action.type === "api/executeMutation/fulfilled" &&
    action.payload?.action === "login"
  ) {
    localStorage.setItem(
      "___reduxState__auth",
      JSON.stringify(store.getState().auth)
    );
  } else return;
};

const middleware = (getDefaultMiddleware: any) =>
  getDefaultMiddleware().concat(
    apiAuth.middleware,
    apiUsers.middleware,
    apiOrders.middleware,
    apiShipments.middleware,
    apiCompositions.middleware,
    apiColors.middleware,
    wasteOrdersApi.middleware,
    adminApi.middleware,
    apiContainers.middleware,
    operationsApi.middleware,
    persistanceMiddleware
  );

export const store = configureStore({
  reducer: {
    [apiAuth.reducerPath]: apiAuth.reducer, // [api.reducerPath] is the name of the slice that we defined in api.ts
    [apiUsers.reducerPath]: apiUsers.reducer, // [api.reducerPath] is the name of the slice that we defined in api.ts
    [apiOrders.reducerPath]: apiOrders.reducer, // [api.reducerPath] is the name of the slice that we defined in api.ts
    [apiShipments.reducerPath]: apiShipments.reducer,
    [apiCompositions.reducerPath]: apiCompositions.reducer,
    [apiColors.reducerPath]: apiColors.reducer,
    [wasteOrdersApi.reducerPath]: wasteOrdersApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [apiContainers.reducerPath]: apiContainers.reducer,
    [operationsApi.reducerPath]: operationsApi.reducer,
    users: userReducer,
    auth: authReducer,
    orders: ordersReducer,
    shipments: shipmentsReducer,
    compositions: compositionsReducer,
  },
  middleware,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
