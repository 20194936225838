import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useCreateTextilTypeMutation,
  useGetTextilTypesMutation,
} from "src/api/textilTypes";
import SnackBar from "src/components/SnackBar";
import AddedMaterials from "./AddedMaterials";
import { number } from "echarts";

export interface CompositionProps {
  materials: any;
  setMaterials: any;
  percentages: any;
  setPercentages: any;
  series?: any;
  specifyComposition: any;
  setSpecifyComposition: any;
  isOnlyRead?: boolean;
}
function Composition({
  materials,
  setMaterials,
  percentages,
  setPercentages,
  series,
  specifyComposition,
  setSpecifyComposition,
  isOnlyRead,
}: CompositionProps) {
  //console.log("Composition/materials: ", materials); //JSM 20240306
  //console.log("Composition/percentages: ", percentages); //JSM 20240306
  const { t } = useTranslation();
  const theme = useTheme();
  const [materialInput, setMaterialInput] = useState("");
  const [materialIdInput, setMaterialIdInput] = useState(null); //JSM 20240509
  const [openError, setOpenError] = useState(false);
  //const [percentageInput, setPercentageInput] = useState(""); //JSM 20240305
  const [percentageInput, setPercentageInput] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [getTextilTypes, { data: textilTypes, isLoading: textilTypesLoading }] =
    useGetTextilTypesMutation();
  const [createTextilType, { data: createdTextilType, isSuccess }] =
    useCreateTextilTypeMutation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const unidentifiedMaterialId = 1; //JSM20240314 abans 12 ////JSM Id=12 és material "No identificado"???? Si és així s'hauria de guardar env var!!!! ara 12 ara 1
  const [errorMessage, setErrorMessage] = useState(null);

  //pot ser que peti aqui perqie percentatges te un array amb numbers i strings
  const updatedPercentages = () => {
    setPercentages((prevPercentages) => {
      const updatedPercentages = prevPercentages.map((percentage, index) => {
        if (
          materials[index]?.textil_type?.id === unidentifiedMaterialId ||
          materials[index]?.id === unidentifiedMaterialId
        ) {
          //console.log("updatedPercentages/material array: ", materials[0]);
          // if ( materials[index]?.textil_type?.id === unidentifiedMaterialId || materials[index].id === unidentifiedMaterialId) {
          //console.log("Materials array: ", materials[index]);
          const result = Number(percentage) - Number(percentageInput);
          const isDecimal = result % 1 !== 0;
          return isDecimal ? result.toFixed(2) : result;
        } else {
          return percentage;
        }
      });
      updatedPercentages.push(percentageInput);
      return updatedPercentages;
    });
  };

  const addMaterial = () => {
    console.log({
      materials,
      percentages,
    });
    //if (materialInput === "" || percentageInput === "") return; //JSM 20240305
    if (materialInput === "" || percentageInput === 0) return;
    // if (materialIdInput === null || percentageInput === 0) return;  //JSM 20240509 TODO
    const sum = percentages.reduce((a, b) => Number(a) + Number(b), 0);
    const SumWithoutOthers = sum - percentages[0];
    if (Number(percentageInput) + Number(SumWithoutOthers) > 100) {
      setErrorMessage("notGreaterThan100");
      setOpenError(true);
      return;
    }
    // if (Number(percentageInput) <= 0) {  // JSM 20240314
    //   setErrorMessage("notLessThanZero");
    if (Number(percentageInput) <= 0 || Number(percentageInput) > 100) {
      // JSM 20240314
      setErrorMessage("percentageBetween1-100");
      setOpenError(true);
      return;
    }
    ////JSM Està buscant x nom? i què passarà quan hi hagi altres idiomes??? TODO buscar x textile_type_Id
    const textilExists = textilTypes.find(
      (textil) => textil.name === materialInput
    );
    // const textilExists = textilTypes.find(  //JSM 20240509 TODO buscar x ID no x nom
    //   (textil) => textil.name === materialIdInput
    // );
    if (!textilExists)
      createTextilType({ name: materialInput, description: null });
    else {
      setMaterials([...materials, textilExists]);
      updatedPercentages();
      setMaterialInput("");
      setMaterialIdInput(null); //JSM 20240509
      //setPercentageInput(""); //JSM 20240305
      setPercentageInput(0);
      setSelectedOption(null);
    }
  };

  const handleDeleteMaterial = (index) => {
    setMaterials(materials.filter((item, i) => i !== index));

    let percentagesList = [...percentages];
    percentagesList = percentagesList.map((item, i) => {
      if (materials[i]?.id === unidentifiedMaterialId) {
        return Number(percentagesList[i]) + Number(percentages[index]);
      } else {
        return item;
      }
    });

    setPercentages(percentagesList.filter((item, i) => i !== index));
  };

  const handleOnChange = (event, value) => {
    if (value === null) {
      setMaterialInput(event.target.value);
      setMaterialIdInput(event.target.value); //JSM 20240509 TODO quin valor he de passar?
    } else {
      setMaterialInput("");
      setMaterialIdInput(null); //JSM 20240509
      setSelectedOption(value);
    }
  };

  useEffect(() => {
    getTextilTypes();
  }, []);

  useEffect(() => {
    if (textilTypes && materials) {
      const notSpecifiedMaterial = textilTypes.find(
        (textil) => textil.id === unidentifiedMaterialId
      );
      // const materialExists = materials.find(
      //   (material) =>
      //     material.id === unidentifiedMaterialId ||
      //     material.description == notSpecifiedMaterial.description
      // );
      const materialExists = () => {
        if (materials.length > 0) {
          return true;
        } else {
          return false;
        }
      };

      if (!materialExists) {
        setMaterials([...materials, notSpecifiedMaterial]);
        console.log(
          "Composition/useEffect/percentages (abans i dins !materialExists ): ",
          percentages
        ); //JSM 20240306
        setPercentages([...percentages, 100]);
        console.log(
          "Composition/useEffect/percentages (després i dins !materialExists ): ",
          percentages
        ); //JSM 20240306
      }
    }
  }, [textilTypes, series, materials]);

  useEffect(() => {
    if (isSuccess) {
      getTextilTypes();
      setMaterials([...materials, createdTextilType.textil_type]);
      updatedPercentages();
      setMaterialInput("");
      setMaterialIdInput(null); //JSM 20240509
      //setPercentageInput(""); //JSM 20240305
      setPercentageInput(0);
      setSelectedOption(null);
    }
  }, [isSuccess]);

  return (
    <>
      <Grid container spacing={1} mt={0}>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox checked={specifyComposition} disabled={isOnlyRead} />
            }
            value="start"
            label={t("specify")}
            onClick={() =>
              !isOnlyRead && setSpecifyComposition(!specifyComposition)
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={specifyComposition == false}
                disabled={isOnlyRead}
              />
            }
            value="start"
            label={t("leaveUnspecified")}
            onClick={() =>
              !isOnlyRead && setSpecifyComposition(!specifyComposition)
            }
          />
        </Grid>
      </Grid>
      {specifyComposition && (
        <>
          <AddedMaterials
            materials={materials}
            percentages={percentages}
            handleDeleteMaterial={handleDeleteMaterial}
            isOnlyRead={isOnlyRead}
          />
        </>
      )}
      {!isOnlyRead && specifyComposition && (
        <>
          <Typography variant="h4" gutterBottom mt={2} mb={1}>
            {t("addTextilType")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={10} sm={11}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isSmallScreen ? "column" : "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Autocomplete
                  freeSolo
                  fullWidth
                  options={
                    textilTypes ? textilTypes.map((textil) => textil.name) : []
                  }
                  value={selectedOption}
                  loading={textilTypesLoading}
                  inputValue={materialInput}
                  onInputChange={(event, newValue) => {
                    setMaterialInput(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label={t("Material")} />
                  )}
                  onChange={handleOnChange}
                  disableClearable={true}
                  sx={{ m: 1, minWidth: "150px" }}
                />
                <TextField
                  name="percentage"
                  type="number"
                  label={t("percentage")}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                  inputProps={{ min: "0" }}
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  //onChange={(e) => setPercentageInput(e.target.value)}  //JSM 20240305
                  onChange={(e) =>
                    setPercentageInput(parseInt(e.target.value, 10))
                  }
                  value={percentageInput}
                  sx={{ m: 1, minWidth: "150px" }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Tooltip title={t("add")} arrow>
                <span onClick={() => addMaterial()}>
                  <IconButton
                    sx={{
                      color: theme.palette.primary.main,
                      background: theme.colors.primary.lighter,
                    }}
                    color="inherit"
                    size="small"
                    disabled={
                      !textilTypes ||
                      !textilTypes.some(
                        (textil) => textil.name === materialInput
                      ) ||
                      materialInput === "" ||
                      //percentageInput === ""  //JSM 20240305
                      percentageInput === 0
                    }
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </>
      )}
      <SnackBar
        open={openError}
        setOpen={setOpenError}
        type="error"
        message={t(errorMessage)}
      />
    </>
  );
}

export default Composition;
