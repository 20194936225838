import { Box, CircularProgress, Grid, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateOrderHeaderRequest } from "src/api/orders";
import { useAppSelector } from "src/hooks/store";
import renderInputs from "./renderOrderHeaderInputs";
import { useGetAllOrganizationsMutation } from "src/api/organizations";
import { useGetFacilityTypesMutation } from "src/api/facilityTypes";
import { useGetLogisticCentersMutation } from "src/api/logisticCenters";
import { CreateShipmentRequest, useGetShipmentsMutation,} from "src/api/shipments";
import { useGetDeliveryConditionsMutation } from "src/api/deliveryCondition";
import { useGetSourceTypesMutation } from "src/api/sourceTypes";
import { Organization } from "src/models/organization";

interface CreateOrderHeaderProps {
  orderData: any;
  shipmentData: any;
  setOrderData: any;
  activeStep: any;
  handleBack: any;
  handleNext: any;
  authInfo: any; //JSM 20240423
  originComponent: any //JSM 20240424
}

const CreateOrderHeaderForm: React.FC<CreateOrderHeaderProps> = ({
  orderData,
  shipmentData,
  setOrderData,
  activeStep,
  handleBack,
  handleNext,
  authInfo, //JSM 20240423
  originComponent, //JSM 20240424

}) => {

  const organization_id = authInfo?.organization_id; //JSM 20240423
  const player_type = authInfo?.logistic_center_id?.player_type.type; //JSM 20240422 
  const userLogistic_center_id = authInfo?.logistic_center_id?.id; //JSM 20240425
  const userEmail = authInfo?.email; //JSM 20240425

  const { t } = useTranslation();
  //const [getWamOrgs, { data: organizations, isLoading }] = useGetWamOrgsMutation(); //JSM 20240416
  const [selectedWamOrg, setSelectedWamOrg] = useState<any>(null);
  const [selectedOriginOrg, setSelectedOriginOrg] = useState<any>(null); //JSM 20240416
  const [selectedDestinationOrg, setSelectedDestinationOrg] = useState<any>(null); //JSM 20240430
  const [getFacilityTypes, { data: facilityTypes, isLoading: isLoadingFacilityTypes },] = useGetFacilityTypesMutation();
  const [getLogisticCenters, { data: logisticCenters, isLoading: isLoadingLogisticCenters },] = useGetLogisticCentersMutation();
  // const [getShipments, { data: shipments, isLoading: isLoadingShipments }] = useGetShipmentsMutation();
  // const [getDeliveryConditions, { data: deliveryConditions, isLoading: isLoadingDeliveryConditions },] = useGetDeliveryConditionsMutation();
  const [getSourceTypes, { data: sourceTypes, isLoading: isLoadingSourceTypes },] = useGetSourceTypesMutation();
  const [getAllOrganizations, { data: organizations, isLoading: organizationsLoading }] = useGetAllOrganizationsMutation();  //JSM 20240416
  const [filteredOriginOrganizations, setFilteredOriginOrganizations] = useState<Organization[]>(organizations);
  const [filteredDestinationOrganizations, setFilteredDestinationOrganizations] = useState<Organization[]>(organizations);

  useEffect(() => {setSelectedWamOrg(orderData?.waste_management_company);}, []);
  useEffect(() => {setSelectedOriginOrg(orderData?.waste_management_company);}, []); //JSM 20240416 TODO guardar la org origin


  useEffect(() => {
    setSelectedWamOrg(orderData?.waste_management_company);
    setSelectedDestinationOrg(orderData?.waste_management_company); //JSM 20240430 TODO guardar la org destí
    //getWamOrgs();
    getAllOrganizations();  //JSM 20240416
    getLogisticCenters();
    getSourceTypes();
    getFacilityTypes();
    // getShipments();
    // getDeliveryConditions();
  }, []);

  useEffect(() => {
    //getWamOrgs();  //JSM 20240424
    let filteredOriginOrganizationsResult;
    if (organizations) {
      //// JSM Entra si és CreateOrderComponent
      if (originComponent == "CreateOrderComponent") {
        if (userLogistic_center_id) {
        //JSM 20240424 Si es Envío d'un user d'una org concreta, fixem la origin org
        filteredOriginOrganizationsResult = organizations.filter(organization => organization.id == organization_id);
        } else {
        //JSM 20240424 Si es Envío && NO user d'una org concreta, NO fixem la origin org
        filteredOriginOrganizationsResult = organizations;
        }
      } else {
        //JSM 20240426 Si es "Recepción sin envío" NO fixem la origin org
        filteredOriginOrganizationsResult = organizations;
      }
    }
    if (filteredOriginOrganizationsResult) {
      setFilteredOriginOrganizations(filteredOriginOrganizationsResult);
    }

    //JSM 20240424 Si es WAPGM fixem sourceType = 4 (Garment Manufacturer (Taller)
    if (sourceTypes && (player_type == "WAPGM")) {
      setOrderData((prevData) => ({
        ...prevData,
        source_type: 4,
      }));
    }

    //JSM 20240514
    let filteredDestinationOrganizationsResult = [];
    if ((organizations) && (originComponent == "ReceptionNoShipment")) {
      if (userEmail == "integer.admin@integer.com") { //JSM 20240514 TODO canviar aquest check x iSSAdmin
        filteredDestinationOrganizationsResult = [...organizations];
      } else if (organization_id) {
        filteredDestinationOrganizationsResult = organizations.filter(organization => organization.id === organization_id);
    } else {
        filteredDestinationOrganizationsResult = [...organizations];
      }
      // filteredDestinationOrganizationsResult = organizations.filter(organization => {
      //   switch (player_type) {
      //     // case "WAM":
      //     //   return organization.player_types.some(playerType => playerType === 5);
      //     case "WAPGM":
      //       return organization.player_types.some(playerType => playerType === 14); //14 == PACK
      //     default:
      //       return organization.player_types;  //JSM 20240514
      //   }
      // });
    }
  
    if (filteredDestinationOrganizationsResult) {
      setFilteredDestinationOrganizations(filteredDestinationOrganizationsResult);
    }

  }, [organizations, sourceTypes]);


  const isLoadingData =
    // isLoading || //JSM 20240416
    organizationsLoading ||
    isLoadingLogisticCenters ||
    isLoadingSourceTypes ||
    isLoadingFacilityTypes
    // || isLoadingShipments ||
    // || isLoadingDeliveryConditions
    ;

  const formFields = [
    // Material info -----------------------------------------------------------------------
    {
      type: "title",
      label: t("materialReceivedInfo"), // JSM 20240416
      isVisible: true,
    },
    {
      name: "order_number",
      label: t("orderNumber"),
      type: "string",
      xs: 12,
      sm: 6,
      defaultValue: orderData?.order_number,
    },

    //JSM 20240424 Si NO és WAPGM mostrem camp sourceType 
    ...(player_type != "WAPGM"
    ? [
      {
        name: "source_type",
        label: t("sourceType"),
        type: "select",
        xs: 12,
        sm: 6,
        items: sourceTypes?.map((sourceType) => ({
          value: sourceType.id,
          label: t(sourceType.name),
        })),
        defaultValue: orderData?.source_type,
      },
      ]
    : []),

    // Origin info -----------------------------------------------------------------------
    {
      type: "title",
      label: t("originInfo"), // JSM 20240416
      isVisible: true,
    },
    {
      name: "origin_company",
      label: t("originCompany"), // JSM 20240416
      type: "select",
      xs: 12,
      sm: 6,
      items: filteredOriginOrganizations?.map((organization) => ({
        value: organization.id,
        label: organization.name,
      })),
      defaultValue: filteredOriginOrganizations?.length === 1 ? filteredOriginOrganizations[0].id : orderData?.waste_management_company,
    },
    {
      name: "origin_logistic_center_id",  //JSM 20240417
      // label: t("logisticCenter"),
      label: t("originFacilities"), // JSM 20240416
      type: "select",
      xs: 12,
      sm: 6,

      items: logisticCenters?.filter((logisticCenter) => logisticCenter.organization.id === selectedOriginOrg )?.map((logisticCenter) => ({
          value: logisticCenter.id,
          label: logisticCenter.name + " - " + logisticCenter.organization.name,
        })),
      defaultValue: logisticCenters?.length === 1 ? logisticCenters[0].id : orderData?.logistic_center,
    },

    // Destination info -----------------------------------------------------------------------
    {
      type: "title",
      label: t("destinationInfo"), // JSM 20240416
      isVisible: true,
    },
    // {
    //   name: "destination_company",
    //   // label: t("wasteManagementCompany"),
    //   label: t("destinationCompany"), // JSM 20240416
    //   type: "select",
    //   xs: 12,
    //   sm: 6,
    //   items: filteredDestinationOrganizations?.map((organization) => ({
    //     value: organization.id,
    //     label: organization.name,
    //   })),
    //   //defaultValue: orderData?.waste_management_company,  //JSM 20240424
    //   defaultValue: filteredDestinationOrganizations?.length === 1 ? filteredDestinationOrganizations[0].id : orderData?.waste_management_company,
    // },
    {
      name: "destination_company",
      // label: t("wasteManagementCompany"),
      label: t("destinationCompany"), // JSM 20240416
      type: "select",
      xs: 12,
      sm: 6,
      items: filteredDestinationOrganizations?.map((organization) => ({
        value: organization.id,
        label: organization.name,
      })),
      //defaultValue: orderData?.waste_management_company,  //JSM 20240424
      // defaultValue: filteredDestinationOrganizations?.length === 1 ? filteredDestinationOrganizations[0].id : orderData?.waste_management_company,
      defaultValue:
        filteredDestinationOrganizations?.length === 1
          ? {
              value: filteredDestinationOrganizations[0].id,
              label: filteredDestinationOrganizations[0].name,
            }
          : orderData?.waste_management_company
            ? {
                value: orderData?.waste_management_company,
                label: orderData?.waste_management_company_name,
              }
            : null,
      onChange: (value) => setSelectedDestinationOrg(value),
    },

    // {
    //   name: "destination_facility",  //JSM 20240417
    //   // label: t("wasteManagementFacility"),
    //   label: t("destinationFacilities"), // JSM 20240416
    //   type: "select",
    //   xs: 12,
    //   sm: 6,
    //   items: facilityTypes
    //     ?.filter(
    //       (facilityType) => facilityType.organization.id === selectedWamOrg
    //     )
    //     ?.map((facilityType) => ({
    //       value: facilityType.id,
    //       label: t(facilityType.name) + " - " + facilityType.organization.name,
    //     })),
    //   //defaultValue: orderData?.waste_management_facility,  //JSM 20240424
    //   defaultValue: facilityTypes?.length === 1 ? facilityTypes[0].id : orderData?.waste_management_facility,
    // },
    {
      name: "destination_facility",
      // label: t("wasteManagementFacility"),
      label: t("destinationFacilities"), // JSM 20240416
      type: "select",
      xs: 12,
      sm: 6,
      items: facilityTypes
        ?.filter(
          // (facilityType) => facilityType.organization.id === selectedWamOrg
          (facilityType) =>
            facilityType.organization.id === selectedDestinationOrg //JSM 20240430
        )
        ?.map((facilityType) => ({
          value: facilityType.id,
          // label: t(facilityType.name) + " - " + facilityType.organization.name,
          label: facilityType.name + " - " + facilityType.organization.name,
        })),
      //defaultValue: orderData?.waste_management_facility,  //JSM 20240424
      defaultValue:
        facilityTypes?.length === 1
          ? facilityTypes[0].id
          : orderData?.waste_management_facility,
    },
    
    // Transportation info -----------------------------------------------------------------------
    {
      type: "title",
      label: t("transportationInfo"), // JSM 20240416
      isVisible: true,
    },
    {
      name: "reception_date",
      label: t("receptionDateConfirmed"),
      type: "date",
      xs: 12,
      sm: 6,
      defaultValue:
        shipmentData?.reception_date?.slice(0, 10) ||
        new Date().toISOString().split("T")[0],
    }

    /*,{
      name: "delivery_condition",
      label: t("deliveryCondition"),
      type: "select",
      xs: 12,
      sm: 6,
      items: deliveryConditions?.map((deliveryCondition) => ({
        value: deliveryCondition.id,
        label: t(deliveryCondition.name),
      })),
      defaultValue: orderData?.delivery_condition,
    }*/ 
    /* //MNS no volem el shipping date ni el reception date
      {
      name: "shipping_date",
      label: t("shipping_date"),
      type: "date",
      xs: 12,
      sm: 6,
      defaultValue:
        shipmentData?.shipping_date?.slice(0, 10) ||
        new Date().toISOString().split("T")[0],
    },
    */
  ];

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const newRequestDate = new Date().toISOString();
    const shippingDate = new Date().toISOString();
    /*formData.get("shipping_date") as string*/
    const receptionDate = new Date().toISOString();
    /*formData.get("reception_date") as string*/
    const originId = Number(formData.get("logistic_center_id") as string);
    const origin = logisticCenters?.find(
      (logisticCenter) => logisticCenter.id === originId
    );
    const destinationId = Number(
      formData.get("destination_facility") as string  //JSM 20240417
    );
    const destination = facilityTypes?.find(
      (facilityType) => facilityType.id === destinationId
    );
    const shipmentFinal: CreateShipmentRequest = {
      origin:
        origin?.address +
        ", " +
        origin?.province.name +
        ", " +
        origin?.country?.name,
      destination:
        destination?.address +
        ", " +
        destination?.province.name +
        ", " +
        destination?.country?.name,
      shipping_date: shippingDate,
      reception_date: receptionDate,
      shipping_company_id: 3,
      carrier_id: authInfo?.id,
      responsible_id: authInfo?.id,
      status_id: 1,
      sender_id: origin?.organization?.id,
      receiver_id: destination?.organization?.id,
    };
    const finalData: CreateOrderHeaderRequest = {
      order_number: formData.get("order_number") as string,
      request_date: newRequestDate,
      waste_management_company: parseInt(
        formData.get("destination_company") as string  //JSM 20240417
      ),
      validated: 0,
      owner_id: authInfo?.id as number,
      shipment: parseInt(formData.get("shipment") as string),
      logistic_center: parseInt(formData.get("origin_logistic_center_id") as string),   //JSM 20240417
      source_type: parseInt(formData.get("source_type") as string),
      waste_management_facility: parseInt(
        formData.get("destination_facility") as string  //JSM 20240417
      ),
      delivery_condition: null,
    };
    setOrderData((prevData) => ({
      ...prevData,
      header: finalData,
      shipment: shipmentFinal,
    }));
    handleNext();
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        {isLoadingData ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              sx={{ marginTop: 5 }}
              size={64}
              disableShrink
              thickness={3}
            />
          </Box>
        ) : (
          <>
            <Typography variant="h3" gutterBottom mt={0} mb={3}>
              {/* {t("deliveryInformation")} //JSM 20240424*/}
              {t("noDocumentedReceptionInfo")}
            </Typography>
            <Grid container spacing={3}>
              {/* //JSM 20240417 */}
              {renderInputs(
                formFields, 
                selectedWamOrg, setSelectedWamOrg, 
                selectedOriginOrg, setSelectedOriginOrg,
                selectedDestinationOrg, setSelectedDestinationOrg
              )}
            </Grid> 
          </>
        )}
        {!isLoadingData && (
          <Box
            sx={{ marginTop: 5, display: "flex", justifyContent: "flex-end" }}
          >
            <Button variant="contained" type="submit" color="primary">
              {t("next")}
            </Button>
          </Box>
        )}
      </form>
    </>
  );
};

export default CreateOrderHeaderForm;
