import { createSlice } from '@reduxjs/toolkit';
import { apiOrders } from 'src/api/orders';

type OrdersState = {
  orderHeader: any;
  orderLine: any;
  messageCreated: string | null;
};

const DEFAULT_STATE: OrdersState = {
  orderHeader: null,
  orderLine: null,
  messageCreated: null,
};

const initialState = () => {
  const persistedState = localStorage.getItem('reduxState');
  if (persistedState) {
    return JSON.parse(persistedState);
  }
  return DEFAULT_STATE;
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialState,
  reducers: {
    createOrderHeaderSuccess: (state, action) => {
      state.orderHeader = action.payload;
    },
    createOrderLinesSuccess: (state, action) => {
      state.orderLine = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiOrders.endpoints.createOrderHeader.matchFulfilled,
      (state, { payload: response }) => {
        state.orderHeader = response.order_header;
      }
    );
    builder.addMatcher(
      apiOrders.endpoints.createOrderLines.matchFulfilled,
      (state, { payload: response }) => {
        state.orderLine = response;
      }
    );
  },
});

export default ordersSlice.reducer;
export const { createOrderHeaderSuccess, createOrderLinesSuccess } =
  ordersSlice.actions;
