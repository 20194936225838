import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Grid, Typography } from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridValueSetterParams,
} from "@mui/x-data-grid";
import { t } from "i18next";
import React, { Dispatch, FC, SetStateAction, useEffect } from "react";
import { useGetAllContainerZonesMutation } from "src/api/containerZones";
import { useGetAllLocationsMutation } from "src/api/locations";
import { Loading } from "src/components/Loading/Loading";
import { IContainerDataGridRow } from "src/models/container";
import { ContainerZoneDTO } from "src/models/containerZone.dto";
import { LocationsDTO } from "src/models/locations.dto";

interface IProps {
  isOnlyRead: boolean;
  selectedContainers: IContainerDataGridRow[];
  setSelectedContainers: Dispatch<SetStateAction<IContainerDataGridRow[]>>;
}

export const AssignContainerZone: FC<IProps> = (props) => {
  const {
    selectedContainers = [],
    setSelectedContainers,
    isOnlyRead = false,
  } = props;

  const [getAllContainerZones, { data: containerZones, isLoading }] =
    useGetAllLocationsMutation();

  useEffect(() => {
    getAllContainerZones();
  }, []);

  const removeContainer = (container: IContainerDataGridRow) => {
    setSelectedContainers((prev) =>
      prev.filter(({ containerID }) => containerID !== container.containerID)
    );
  };

  const processRowUpdate = (newRow: IContainerDataGridRow) => {
    setSelectedContainers((prev) =>
      prev.map((prevContainer) => {
        if (prevContainer.containerID === newRow.containerID) {
          return newRow;
        }
        return prevContainer;
      })
    );

    return newRow;
  };

  const columns = React.useMemo<GridColDef[]>(() => {
    return [
      {
        field: "actions",
        type: "actions",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <DeleteOutlinedIcon color={isOnlyRead ? "disabled" : "error"} />
            }
            label="Delete"
            onClick={() => !isOnlyRead && removeContainer(params.row)}
          />,
        ],
      },
      {
        field: "containerName",
        headerName: t("container"),
        flex: 1,
        editable: false,
      },
      //JSM 20240228  , MNS 04042024
      {
        field: "containerWeight",
        headerName: t("weightInCont"),
        flex: 1,
        editable: true,
        type: "number",
        valueSetter: (
          params: GridValueSetterParams<IContainerDataGridRow>
        ): IContainerDataGridRow => {
          const rowData = params.row;
          const newWeight = parseFloat(params.value); // Convert the value to a float

          // Update the container weight if it's different from the current value
          if (newWeight !== rowData.containerWeight) {
            return {
              ...rowData,
              containerWeight: newWeight,
            };
          }

          return { ...rowData };
        },
      },
      {
        field: "containerZoneName",
        headerName: t("assignedContainerZone"),
        flex: 1,
        editable: true,
        type: "singleSelect",
        valueOptions: containerZones,
        getOptionLabel: (option: LocationsDTO) => option.location,
        getOptionValue: (option: LocationsDTO) => option.id,
        valueGetter: (option) => {
          if (!option?.value) return "";

          const zoneName = option?.value || "";

          return (
            containerZones?.find(({ location }) => location === zoneName)?.id ||
            ""
          );
        },
        valueSetter: (
          params: GridValueSetterParams<IContainerDataGridRow>
        ): IContainerDataGridRow => {
          const rowData = params.row;
          const containerZone = containerZones?.find(
            ({ id }) => id === params.value
          );

          if (
            containerZone &&
            rowData &&
            rowData.containerZoneID != containerZone.id
          ) {
            return {
              ...rowData,
              containerZoneID: containerZone.id,
              containerZoneName: containerZone.location,
            };
          }

          return { ...rowData };
        },
      },
    ];
  }, [containerZones]);

  if (selectedContainers?.length === 0 || containerZones?.length === 0)
    return <React.Fragment />;

  return (
    <Grid item xs={12} sm={12} pb={2}>
      <Typography variant="h5" component="h5" py={2}>
        {t("assignZoneToContainer")}
      </Typography>

      <Loading isLoading={isLoading} />

      <DataGrid
        rows={selectedContainers}
        columns={columns}
        getRowId={(row) => row.containerID}
        hideFooter={true}
        processRowUpdate={processRowUpdate}
      />
    </Grid>
  );
};
