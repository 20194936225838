import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';
import { CreateProductRequest, Product } from './products';

export interface WasteOrderLine {
	id: number;
	waste_order_id: number;
	product_id: number;
	product: Product;
};

export interface WasteOrder {
	id: number;
	center_id: number;
	client_id: number;
	cutting_order_reference: string;
	model_reference: string;
	start_date: Date;
	end_date: Date | null;
	status_id: number;
	lines: WasteOrderLine[];
};

export interface WasteOrderLineCreateRequest {
	product: CreateProductRequest;
};
export interface WasteOrderCreateRequest {
	client_id: number;
	cutting_order_reference?: string | null;
	model_reference: string;
	start_date: Date;
	end_date?: Date | null;
	lines: WasteOrderLineCreateRequest[];
};

export interface WasteOrderListRequest {
	status_id?: number;
};

export interface WasteOrderLineUpdateRequest {
	id?: number;
	product: CreateProductRequest;
};

export interface WasteOrderLineUpdateStatusRequest {
	id?: number;
	waste_order_line_status_id: number | null;
};

export interface WasteOrderUpdateRequest {
	id: number,
	client_id?: number;
	cutting_order_reference?: string | null;
	model_reference?: string;
	start_date?: Date | null;
	end_date?: Date;
	status_id?: number;
	lines?: WasteOrderLineUpdateRequest[];
};

export const wasteOrdersApi = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath: 'wasteOrders',
	endpoints: (builder) => ({
		listWasteOrders: builder.mutation<WasteOrder[], WasteOrderListRequest | void>({
			query: (request) => ({
				url: `api/waste-orders${request && request?.status_id ? `?status_id=${request.status_id}` : ''}`,
				method: 'GET',
			}),
		}),
		getWasteOrder: builder.mutation<WasteOrder, number>({
			query: id => ({
				url: `api/waste-orders/${id}`,
				method: 'GET',
			}),
		}),
		createWasteOrder: builder.mutation<any, WasteOrderCreateRequest>({
			query: body => ({
				url: 'api/waste-orders/create',
				method: 'POST',
				body
			}),
		}),
		updateWasteOrder: builder.mutation<any, WasteOrderUpdateRequest>({
			query: body => ({
				url: `api/waste-orders/${body.id}`,
				method: 'PUT',
				body
			}),
		}),
		updateWasteOrderLine: builder.mutation<any, WasteOrderLineUpdateStatusRequest>({
			query: body => ({
				url: 'api/waste-orders/updateLine',
				method: 'PUT',
				body
			})
		})
	}),
});

export const {
	useListWasteOrdersMutation,
	useGetWasteOrderMutation,
	useCreateWasteOrderMutation,
	useUpdateWasteOrderMutation,
	useUpdateWasteOrderLineMutation
} = wasteOrdersApi;
