import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';
import { Location } from 'src/models/location';

export const apiLocations = createApi({
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getAllLocations: builder.mutation<Location[], void>({
      query: () => ({
        url: 'api/locations/getAll',
        method: 'GET',
      }),
    }),
    getAllContainerLocationColumns: builder.mutation<any[], void>({
      query: () => ({
        url: 'api/locations/getAllLocationColumns',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetAllLocationsMutation,
  useGetAllContainerLocationColumnsMutation,
} = apiLocations;
