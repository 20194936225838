import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from 'src/api/baseQuery';
import { IOrder } from 'src/models/order';
import { IOrderLines } from 'src/models/order_lines';
import { OrderList } from 'src/models/order_list';

const BASE_URL_API = process.env.REACT_APP_BASE_URL;

interface CompositionDetailCreateRequest {
	percentage: number;
	textil_type_id: number;
};

export interface CompositionCreateRequest {
	details: CompositionDetailCreateRequest[];
};

interface ProductCreateRequest {
  owner_id?: number;
	product_type_id: number;
	color_id?: number;
	composition_id?: number;
	closed_circulation?: number,
	weight: number;
	composition?: CompositionCreateRequest;
  comments?: string;
}

interface ContainerCreateRequest {
  reference?: string;
  location_id: number;
  product_id: number | null;
  weight?: number | null;
  container_type_id: number;
  logistic_center_id: number;
};

interface ContainerUpdateRequest {
  id: number,
	reference?: string;
	location_id?: number;
	product_id?: number | null;
	weight?: number | null;
	container_type_id?: number;
	logistic_center_id?: number;
}

interface InventoryCreateRequest {
  container: ContainerCreateRequest | ContainerUpdateRequest;
  product: ProductCreateRequest;
};



export interface CreateOrderHeaderRequest {
  order_number: string;
  request_date: string;
  /*   origin: number; */
  waste_management_company: number;
  delivery_condition: number;
  // count_of_pallets: number
  validated: number;
  owner_id: number;
  shipment: number;
  logistic_center: number;
  source_type: number;
  waste_management_facility: number;
}

export interface CreateOrderHeaderResponse {
  message: string;
  order_header: OrderList;
}

export interface CreateOrderLinesRequest {
  order_header_id: number;
  model_reference: string;
  quantity: number;
  weight: number;
  num_package: number;
  color_id: number;
  composition: {
    description: string;
    composition_details: any[];
  };
  series: number;
  attachements?: File | null;
  pallets: number;
  description: string;
  closed_circulation: number;
  waste_type: number;
  waste_origin: number;
  rejection_motive: number;
  ler_code: string;
  origin: number;
}

export interface CreateOrderLinesResponse {
  message: string;
  order_line: IOrderLines;
}

export interface DeleteOrderHeaderRequest {
  order_id: string;
}

export interface DeleteOrderHeaderResponse {
  message: string;
}

export interface CreateOrdersProductsRequest {
  order_line_id: number;
  product_id: number;
}

export interface GetOrderProductsByIdRequest {
  order_id: number;
}

export interface GetOrderProductsByIdResponse {
  message: string;
  order_products: any;
}

export interface EditOrderHeaderRequest {
  order_id: number;
  orderHeaderInfo: any;
}

export interface EditOrderLinesRequest {
  order_id: number;
  orderLineInfo: any;
}

export const apiOrders = createApi({
  reducerPath: 'api_orders',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getOrderHeaders: builder.mutation<OrderList[], void>({
      query: (OrderRequest) => ({
        url: `api/orders/getAllOrdersHeader`,
        method: 'GET',
      }),
    }),
    createOrderHeader: builder.mutation<
      CreateOrderHeaderResponse,
      CreateOrderHeaderRequest
    >({
      query: (body) => ({
        url: `api/orders/createOrderHeader`,
        method: 'POST',
        body,
      }),
    }),
    createOrderLines: builder.mutation<
      CreateOrderLinesResponse,
      CreateOrderLinesRequest
    >({
      query: (body) => ({
        url: `api/orders/createOrderLines`,
        method: 'POST',
        body,
      }),
    }),
    deleteOrderHeader: builder.mutation<
      DeleteOrderHeaderResponse,
      DeleteOrderHeaderRequest
    >({
      query: (DeleteOrderHeaderRequest) => ({
        url: `api/orders/deleteOrderHeader?order_id=${DeleteOrderHeaderRequest.order_id}`,
        method: 'DELETE',
      }),
    }),
    getOrderHeaderById: builder.mutation<IOrder, string>({
      query: (id) => ({
        url: `api/orders/getOrderHeader?order_id=${id}`,
        method: 'GET',
      }),
    }),
    getOrderLinesById: builder.mutation<IOrderLines[], string>({
      query: (id) => ({
        url: `api/orders/getOrderLinesByHeaderId?order_id=${id}`,
        method: 'GET',
      }),
    }),
    createOrderProducts: builder.mutation<any, CreateOrdersProductsRequest>({
      query: (body) => ({
        url: `api/orders/createOrderProducts`,
        method: 'POST',
        body,
      }),
    }),
    getOrderProductsById: builder.mutation<
      GetOrderProductsByIdResponse,
      GetOrderProductsByIdRequest
    >({
      query: (GetOrderProductsByIdRequest) => ({
        url: `api/orders/getOrderProductsByLineId?order_id=${GetOrderProductsByIdRequest.order_id}`,
        method: 'GET',
      }),
    }),
    updateStatusOrderHeader: builder.mutation<any, any>({
      query: (updateStatusOrderRequest) => ({
        url: `api/orders/updateOrderHeaderStatus`,
        method: 'PUT',
        body: updateStatusOrderRequest,
      }),
    }),
    editOrderHeader: builder.mutation<any, EditOrderHeaderRequest>({
      query: (EditOrderHeaderRequest) => ({
        url: `api/orders/editOrderHeader?order_id=${EditOrderHeaderRequest.order_id}`,
        method: 'PUT',
        body: EditOrderHeaderRequest.orderHeaderInfo,
      }),
    }),
    editOrderLines: builder.mutation<any, EditOrderLinesRequest>({
      query: (EditOrderLinesRequest) => ({
        url: `api/orders/editOrderLines?order_id=${EditOrderLinesRequest.order_id}`,
        method: 'PUT',
        body: EditOrderLinesRequest.orderLineInfo,
      }),
    }),
    createInventory: builder.mutation<any, InventoryCreateRequest>({
      query: (requestBody) => ({
        url: `api/orders/inventory`,
        method: 'POST',
        body: requestBody,
      })
    })
  }),
});

export const {
  useCreateOrderHeaderMutation,
  useCreateOrderLinesMutation,
  useGetOrderHeadersMutation,
  useDeleteOrderHeaderMutation,
  useGetOrderHeaderByIdMutation,
  useGetOrderLinesByIdMutation,
  useCreateOrderProductsMutation,
  useGetOrderProductsByIdMutation,
  useUpdateStatusOrderHeaderMutation,
  useEditOrderHeaderMutation,
  useEditOrderLinesMutation,
  useCreateInventoryMutation,
} = apiOrders;
