import { Grid, MenuItem, TextField, Typography } from "@mui/material";

export default function renderInputs(formFields: any[], selectedWamOrg, setSelectedWamOrg, selectedOriginOrg, setSelectedOriginOrg, selectedDestinationOrg, setSelectedDestinationOrg) {
  return formFields.map((field, key) => {
    if (field.type === "select" && field.name === "origin_company") {  //JSM 20240417
      return (
        <Grid key={key} item xs={field.xs} sm={field.sm}>
          <TextField
            name={field.name}
            label={field.label}
            defaultValue={field.defaultValue || ""}
            select
            fullWidth
            required
            //disabled={field.name === "destination_facility" && selectedOriginOrg === null}
            onChange={(e) => setSelectedOriginOrg(e.target.value)}
          >
            {field.items?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      );
    } 
    else if (field.type === "select" && field.name === "origin_logistic_center_id") {  //JSM 20240429
      return (
        <Grid key={key} item xs={field.xs} sm={field.sm}>
          <TextField
            name={field.name}
            label={field.label}
            defaultValue={field.defaultValue || ""}
            select
            fullWidth
            required
            disabled={field.name === "origin_logistic_center_id" && selectedOriginOrg === null}
            //onChange={(e) => setSelectedOriginOrg(e.target.value)}
          >
            {field.items?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      );
    } 
    else if (field.type === "select" && field.name === "destination_company") {  //JSM 20240417
      return (
        <Grid key={key} item xs={field.xs} sm={field.sm}>
          <TextField
            name={field.name}
            label={field.label}
            defaultValue={field.defaultValue || ""}
            select
            fullWidth
            required
            // onChange={(e) => setSelectedWamOrg(e.target.value)}  //JSM 20240514
            onChange={(e) => setSelectedDestinationOrg(e.target.value)}
          >
            {field.items?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      );
    } 
    else if (field.type === "select" && field.name === "destination_facility") {
      return (
        <Grid key={key} item xs={field.xs} sm={field.sm}>
          <TextField
            name={field.name}
            label={field.label}
            defaultValue={field.defaultValue || ""}
            select
            fullWidth
            required
            //disabled={field.name === "destination_facility" && selectedWamOrg === null}  //JSM 20240417
          >
            {field.items?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      );
    } 
    else if (field.type === "select") {
      return (
        <Grid key={key} item xs={field.xs} sm={field.sm}>
          <TextField
            name={field.name}
            label={field.label}
            defaultValue={field.defaultValue || ""}
            select
            fullWidth
            required
            //disabled={field.name === "origin_logistic_center_id" && selectedOriginOrg === null}  //JSM 20240417
          >
            {field.items?.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      );
    } else if (field.type === "date") {
      return (
        <Grid key={key} item xs={field.xs} sm={field.sm}>
          <TextField
            name={field.name}
            type={field.type}
            label={field.label}
            defaultValue={field.defaultValue}
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
          />
        </Grid>
      );
    } else if (field.type === "number") {
      return (
        <Grid key={key} item xs={field.xs} sm={field.sm}>
          <TextField
            name={field.name}
            type={field.type}
            label={field.label}
            fullWidth
            required
          />
        </Grid>
      );
    } else if (field.type === "title") {  // JSM 20240416 Per poder ordenar i posar títol a grups de camps
      return (
        <Grid key={key} item xs={12}>
          <Typography variant="h4">{field.label}</Typography>
        </Grid>
      );
    } 
    
    else {
      return (
        <Grid key={key} item xs={field.xs} sm={field.sm}>
          <TextField
            name={field.name}
            label={field.label}
            defaultValue={field.defaultValue}
            fullWidth
            required
          />
        </Grid>
      );
    }
  });
}
